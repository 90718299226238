import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import moment from 'moment';

import { notifyError } from '../../../../redux/ducks/notification.duck';
import { getAuth } from '../../../../redux/ducks/auth.duck';
import { rsf, firestore } from '../../../../services/firebase';

import {
  callGetListTransactions,
  getSearchCriteria,
  setTransactionsData,
  filterTransactions,
  getAllTransactions,
  getTab,
  callSyncTransactions,
} from './listTransactions.duck';

function* onGetListTransactionsFlow() {
  yield takeLatest(callGetListTransactions, function* onGetListTransactions({
    payload,
  }) {
    const auth = yield select(getAuth);
    if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
      throw new Error('Cannot get Authentication');
    }

    const { uid } = auth;
    const { tab } = payload;
    const isCashTransactions = tab === 0;
    const collection = isCashTransactions
      ? 'withdrawalsCashRequested'
      : 'withdrawalsRequested';
    const ref = firestore.collection(collection);
    const snapshot = yield call(rsf.firestore.getCollection,
      ref.where('uid', '==', uid));

    let withdrawals = [];
    const expiredTransactionsTimeout = isCashTransactions ?
      parseFloat(60 * 1440) : parseFloat(60 * 30);
    snapshot.forEach(item => {
      const obj = item.data();
      obj.id = item.id;
      obj.created = moment(obj.created_at * 1000).format('MMM D, YYYY h:mm a');
      obj.total = parseFloat(obj.amountLessFee).toFixed(2);
      const unix = moment().unix();
      obj.status = 'Pending';
      if (obj.created_at + expiredTransactionsTimeout < unix &&
          !obj.confirmations.user) {
        obj.status = 'Expired';
      } else if (obj.adminDeclined) {
        obj.status = 'Declined';
      } else if (obj.adminConfirmedDateTime !== undefined) {
        obj.status = 'Complete';
      }
      if (tab === 0) {
        withdrawals.push(obj);
      } else if ((obj.paidVia === 'on-chain' || obj.paidVia === 'lightning')
        && tab === 1) {
        obj.method = obj.paidVia;
        obj.total = (Math.ceil(obj.amountLessFee) / 100000000).toFixed(8);
        withdrawals.push(obj);
      } else if ((obj.paidVia === 'stable-coin' && obj.method === 'USDt')
        && tab === 2) {
        withdrawals.push(obj);
      } else if ((obj.paidVia === 'stable-coin' && (obj.method === 'BUSD' ||
        obj.method === 'USDC'))
        && tab === 3) {
        withdrawals.push(obj);
      }
    });

    withdrawals = withdrawals.sort((a, b) =>
      b.created_at - a.created_at);

    yield put(setTransactionsData({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    try {
      // TODO integration: call get list transactions base on cash/coin
      const currentData = yield select(getAllTransactions);
      const currentTab = yield select(getTab);
      if (currentData && currentData.length > 0 &&
        (tab === undefined || tab === currentTab)) {
        yield put(
          setTransactionsData({
            isFetching: false,
            searchCriteria: newSearchCriteria,
            ...filterTransactions(currentData, newSearchCriteria),
          }),
        );
      } else {
        yield put(
          setTransactionsData({
            isFetching: false,
            tab,
            searchCriteria: newSearchCriteria,
            allData: withdrawals,
            ...filterTransactions(withdrawals, newSearchCriteria),
          }),
        );
      }
    } catch (error) {
      yield put(
        setTransactionsData({
          list: [],
          tab,
          totalRecords: 0,
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
      );
    }
  });
}

function* onSyncTransactionsFlow() {
  yield takeLatest(callSyncTransactions, function* onSyncTransactions({
    payload,
  }) {
    const auth = yield select(getAuth);
    if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
      throw new Error('Cannot get Authentication');
    }

    const { uid } = auth;
    const { tab } = payload;
    const isCashTransactions = tab === 0;
    const collection = isCashTransactions
      ? 'withdrawalsCashRequested'
      : 'withdrawalsRequested';
    const ref = firestore.collection(collection);
    const snapshot = yield call(rsf.firestore.getCollection,
      ref.where('uid', '==', uid));

    let withdrawals = [];
    const expiredTransactionsTimeout = isCashTransactions ?
      parseFloat(60 * 1440) : parseFloat(60 * 30);
    snapshot.forEach(item => {
      const obj = item.data();
      obj.id = item.id;
      obj.created = moment(obj.created_at * 1000).format('MMM D, YYYY h:mm a');
      obj.total = parseFloat(obj.amountLessFee).toFixed(2);
      const unix = moment().unix();
      obj.status = 'Pending';
      if (obj.created_at + expiredTransactionsTimeout < unix &&
          !obj.confirmations.user) {
        obj.status = 'Expired';
      } else if (obj.adminDeclined) {
        obj.status = 'Declined';
      } else if (obj.adminConfirmedDateTime !== undefined) {
        obj.status = 'Complete';
      }
      if (tab === 0) {
        withdrawals.push(obj);
      } else if ((obj.paidVia === 'on-chain' || obj.paidVia === 'lightning')
        && tab === 1) {
        obj.method = obj.paidVia;
        obj.total = (Math.ceil(obj.amountLessFee) / 100000000).toFixed(8);
        withdrawals.push(obj);
      } else if ((obj.paidVia === 'stable-coin' && obj.method === 'USDt')
        && tab === 2) {
        withdrawals.push(obj);
      } else if ((obj.paidVia === 'stable-coin' && obj.method === 'BUSD')
        && tab === 3) {
        withdrawals.push(obj);
      }
    });

    withdrawals = withdrawals.sort((a, b) =>
      b.created_at - a.created_at);

    yield put(setTransactionsData({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    try {
      yield put(
        setTransactionsData({
          isFetching: false,
          tab,
          searchCriteria: newSearchCriteria,
          allData: withdrawals,
          ...filterTransactions(withdrawals, newSearchCriteria),
        }),
      );
    } catch (error) {
      yield put(
        setTransactionsData({
          list: [],
          tab,
          totalRecords: 0,
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
      );
    }
  });
}

export default function* saga() {
  yield all([
    onGetListTransactionsFlow(),
    onSyncTransactionsFlow(),
  ]);
}
