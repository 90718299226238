import {
  takeLatest,
  all,
  put,
  select,
  call,
  takeEvery,
} from 'redux-saga/effects';
import moment from 'moment';

import { notifyError } from '../ducks/notification.duck';
import { getAuth } from '../ducks/auth.duck';
import { rsf, firestore } from '../../services/firebase';

import {
  getSearchCriteria,
  setTransactionsData,
  filterTransactions,
  callGetTransactions,
  calculateIncomeRate,
} from '../ducks/balances.duck';

function* onGetTransactionsFlow() {
  yield takeLatest(callGetTransactions, function* onGetTransactions({
    payload,
  }) {
    const auth = yield select(getAuth);
    if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
      throw new Error('Cannot get Authentication');
    }

    const { uid } = auth;
    const ref = firestore.collection('transactions');
    const snapshot = yield call(rsf.firestore.getCollection,
      ref.where('uid', '==', uid).orderBy('created_at', 'desc'));

    const cashRef = firestore.collection('instantCash');
    const snapshotCash = yield call(rsf.firestore.getCollection,
      cashRef.where('uid', '==', uid)); // .orderBy('created_at', 'desc'));

    const cashData = [];
    const format = 'D MMM YY'; // 'D MMM, YYYY hh:mm:ss a';
    const transactions = [];
    snapshotCash.forEach(item => {
      const obj = item.data();
      obj.id = item.id;
      const amount = parseFloat((obj.instantCash).toFixed(2));
      const createdAt = moment.unix(obj.created_at).format(format);
      const data = { amount, createdAt, time: obj.created_at };
      cashData.push({ data });
      obj.paidVia = 'cash';
      obj.amount = amount;
      transactions.push(obj);
    });

    // const cashData = [];
    const btcData = [];
    const usdtData = [];
    const busdData = [];
    const usdcData = [];
    snapshot.forEach(item => {
      const obj = item.data();
      obj.id = item.id;
      const { status, paidVia } = obj;
      const createdAt = moment.unix(obj.created_at).format(format);
      if (status === 'paid' || status === 'partially-paid'
          || status === 'expired-paid' || status === 'expired-partially-paid') {
        if (paidVia === 'on-chain') {
          // const amount = (Math.floor(obj.priceLessPlatformFee) / 100000000);
          const amount = (Math.floor(obj.priceLessPlatformFee));
          const data = { amount, createdAt, time: obj.created_at };
          btcData.push({ data });
          obj.amount = amount;
          transactions.push(obj);
        } else if (paidVia === 'lightning') {
          const amount = (Math.floor(obj.priceLessPlatformFee));
          const data = { amount, createdAt, time: obj.created_at };
          btcData.push({ data });
          obj.amount = amount;
          transactions.push(obj);
        } else if (paidVia === 'stable-coin') {
          const amount = Number(Number(obj.priceLessPlatformFeeSc).toFixed(2));
          const data = { amount, createdAt, time: obj.created_at };
          if (obj.method === 'BUSD') {
            busdData.push({ data });
          }
          if (obj.method === 'USDt') {
            usdtData.push({ data });
          }
          if (obj.method === 'USDC') {
            usdcData.push({ data });
          }
          obj.amount = amount;
          transactions.push(obj);
        }
      }
    });

    yield put(setTransactionsData({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    try {
      // TODO integration: call get list transactions base on cash/coin
      yield put(
        setTransactionsData({
          isFetching: false,
          searchCriteria: newSearchCriteria,
          allData: transactions,
          cashData,
          btcData,
          usdtData,
          usdcData,
          busdData,
          ...filterTransactions(transactions, newSearchCriteria),
        }),
      );
    } catch (error) {
      yield put(
        setTransactionsData({
          list: [],
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
      );
    }
  });
}

function* onGetIncomeRateFromSalesFlow() {
  yield takeEvery(setTransactionsData,
    function* onGetIncomeRateFromSales({
      payload,
    }) {
      if (payload.isFetching === false) {
        yield put(
          setTransactionsData({
            btcIncomeRate: calculateIncomeRate(payload.btcData),
            usdtIncomeRate: calculateIncomeRate(payload.usdtData),
            busdIncomeRate: calculateIncomeRate(payload.busdData),
            usdcIncomeRate: calculateIncomeRate(payload.usdcData),
            cashIncomeRate: calculateIncomeRate(payload.cashData),
          }),
        );
      }
      yield;
    });
}

export default function* saga() {
  yield all([
    onGetTransactionsFlow(),
    onGetIncomeRateFromSalesFlow(),
  ]);
}
