/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/WITHDRAWALS/WITHDRAW/LNURL';
const initialStates = {
  lnurlModal: { open: false },
  withdrawalLnurl: '',
};

export const setLnurlModal = createAction(`${PREFIX}/SET_MODAL`);
export const setWithdrawalLnurl = createAction(`${PREFIX}/SET_WITHDRAWAL_LNURL`);

export const getLnurlModal = (state) => state.withdrawals.lnurl.lnurlModal;
export const getWithdrawalLnurl = (state) => state.withdrawals.lnurl.withdrawalLnurl;

export default handleActions(
  new Map([
    [
      setLnurlModal,
      (state, { payload }) => ({
        ...state,
        lnurlModal: { ...payload },
      }),
    ],
    [
      setWithdrawalLnurl,
      (state, { payload }) => ({
        ...state,
        withdrawalLnurl: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
