/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/WITHDRAWALS/TRANSACTIONS';
const defaultSearchCriteria = {
  page: 1, // TODO integration: check with API param/query
  limit: 10, // TODO integration: check with API param/query - row per page
  time: undefined, // TODO integration: check with API param/query
  search: '', // TODO integration: check with API param/query
};
const initialStates = {
  isFetching: false,
  // TODO check search on BE or FE then remove or not remove `allData`
  // Currently, all data are pulled and search creteria will be done in FE
  // If we change to search via API
  // just remove `allData` and everthing relate, use only `list`
  allData: [],
  list: [],
  csvData: [],
  tab: 0,
  totalRecords: 0,
  searchCriteria: defaultSearchCriteria,
};

export const callGetListTransactions = createAction(`${PREFIX}/GET_LIST_TRANSACTIONS`);
export const callSyncTransactions = createAction(`${PREFIX}/SYNC_TRANSACTIONS`);

export const setTransactionsData = createAction(`${PREFIX}/SET_LIST_DATA`);

// TODO: remove `getAllTransactions` if no need
export const getAllTransactions = (state) => state.withdrawals.transactions.allData;
export const getListTransactions = (state) => state.withdrawals.transactions.list;
export const getFetching = (state) => state.withdrawals.transactions.isFetching;
export const getTotalRecords = (state) => state.withdrawals.transactions.totalRecords;
export const getSearchCriteria = (state) => state.withdrawals.transactions.searchCriteria;
export const getTab = (state) => state.withdrawals.transactions.tab;
export const getCSVData = (state) => state.withdrawals.transactions.csvData;

// Currently, all data are pulled and search creteria will be done in FE
// If we change to search via API, just remove this and everthing relate
export const filterTransactions = (data, criteria = defaultSearchCriteria) => {
  let result = [...data];
  const { page, limit, time, search } = criteria;

  // Time
  if (time) {
    const today = Date.now();
    switch (time) {
      case 'lastWeek': {
        const lastSevenDays = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastSevenDays / 1000);
        break;
      }
      case 'lastMonth': {
        const lastThirtyDays = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastThirtyDays / 1000);
        break;
      }
      default: {
        if (typeof time === 'object' && time.from && time.to) {
          result = result.filter(item =>
            item.created_at >= time.from.getTime() / 1000 &&
            item.created_at <= time.to.getTime() / 1000);
        }
        break;
      }
    }
  }

  // Search
  if (search) {
    result = result.filter(
      (item) =>
        item.status &&
        item.status.toLowerCase().includes(search.toLowerCase()),
    );
  }

  const csvData = result.map(item => {
    const { amount, method, created, currency, status, total, fee } = item;
    if (method === undefined) {
      return {
        'amount before fee': amount,
        amount: total,
        fee: (amount * fee).toFixed(2),
        status,
        date: created,
        currency,
      };
    }
    if (method === 'on-chain' || method === 'lightning') {
      return {
        'amount before fee': parseFloat(amount) / 100000000,
        amount: total,
        fee: item.txFee / 100000000,
        status,
        date: created,
        method,
        coin: 'bitcoin',
        address: item.paidVia === 'on-chain' ? item.address : '',
        txid: item.paidVia === 'on-chain' ? item.txid : item.paymentRequest,
      };
    }
    if (method === 'USDt' || method === 'BUSD') {
      const scFee = amount - total;
      return {
        'amount before fee': amount,
        amount: total,
        fee: scFee.toFixed(2),
        status,
        date: created,
        method: 'stablecoin',
        coin: method,
      };
    }

    return [];
  });
  result = result.slice((page - 1) * limit, page * limit);

  return {
    list: result,
    csvData,
    totalRecords: result.length,
  };
};

export default handleActions(
  new Map([
    [
      setTransactionsData,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
  ]),
  { ...initialStates },
);
