import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import moment from 'moment';

// import axios from 'axios';
import { apiUtil, cookieUtil } from '../../utils';
import {
  initializeAuth,
  callSignIn,
  callSignUp,
  callSignOut,
  callForgotPassword,
  callUpdatePassword,
  callTwoFactorVerifyOTP,
  callTwoFactorGeneration,
  callTwoFactorSetUpVerifyOTP,
  callTwoFactorGetSecretKey,
  callTwoFactorSaveSecretKey,
  callTwoFactorVerifySecretKey,
  callResendEmailVerification,
  callSignUpResendEmailVerification,
  callUpdateInformation,
  callUpdatePrimaryEmail,
  callAddEmailNotification,
  callEditEmailNotification,
  callDeleteEmailNotification,
  setVerified2FAStatus,
  setGGAuthenticator,
  setBackupKey,
  setSurveyComplete,
  getAuth,
  getCurrentUserProfile,
  callTwoFactorDisable,
  callResetPassword,
  callResendEmailOtp,
  callVerifyEmailOtp,
} from '../ducks/auth.duck';
import { rsf, firebase, firebaseAuth } from '../../services/firebase';
import { setLoading } from '../ducks/loading.duck';
import { notifyError, notifySuccess } from '../ducks/notification.duck';

function* onInitializeAuthFlow() {
  yield takeLatest(initializeAuth, function* onInitializeAuth() {
    yield put(setLoading(true));
    const status = cookieUtil.getVerified2FAStatus();
    yield put(setVerified2FAStatus(Boolean(status)));
    yield put(setLoading(false));
    yield put(setSurveyComplete(false));
  });
}

function* onSignInFlow() {
  yield takeLatest(callSignIn, function* onSignIn({ payload: { formValues } }) {
    try {
      const { emailAddress, password } = formValues;
      yield put(setLoading(true));
      // TODO: keepMeIn
      // yield call(
      //   rsf.app.auth().setPersistence,
      //   firebaseAuth.Auth.Persistence.SESSION,
      // );
      yield call(rsf.auth.signInWithEmailAndPassword, emailAddress, password);
    } catch (error) {
      yield put(
        notifyError({ message: 'SIGN_IN.ERROR_MESSAGE.CANNOT_SIGN_IN' }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onSignUpFlow() {
  yield takeLatest(callSignUp, function* onSignUp({
    payload: { formValues, callback },
  }) {
    try {
      yield put(setLoading(true));
      const { companyName, emailAddress, password } = formValues;
      const authUser = yield call(
        rsf.auth.createUserWithEmailAndPassword,
        emailAddress,
        password,
      );
      yield call(rsf.auth.sendEmailVerification, {
        url: `${window.location.origin}/sign-in`,
      });
      yield call(rsf.firestore.setDocument, `users/${authUser.user.uid}`, {
        email: emailAddress,
        image:
          // eslint-disable-next-line max-len
          'https://firebasestorage.googleapis.com/v0/b/symple-f88d7.appspot.com/o/static%2Fuser_logo.png?alt=media&token=f2046b4b-d436-4b33-ad98-8e9013d1fe5e',
        createdAt: moment().unix(),
        defaultCurrency: 'USD',
        defaultBtcDenomination: 'BTC',
        withdrawConfirmationThreshold: 0,
        txFeeThreshold: 0,
        loginSecretSaved: false,
        reEnableTwoFA: false,
        companyName,
      });
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({ message: 'SIGN_UP.ERROR_MESSAGE.CANNOT_SIGN_UP' }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onSignUpResendEmailVerificationFlow() {
  yield takeLatest(
    callSignUpResendEmailVerification,
    function* onSignUpResendEmailVerification({ payload: { callback } }) {
      try {
        yield call(rsf.auth.sendEmailVerification, {
          url: `${window.location.origin}/sign-in`,
        });
        yield put(
          notifySuccess({ message: 'SIGN_UP.SUCCESS_MESSAGE.RESEND_EMAIL' }),
        );
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({ message: 'SIGN_UP.ERROR_MESSAGE.CANNOT_RESEND_EMAIL' }),
        );
      }
    },
  );
}

function* onResendEmailVerificationFlow() {
  yield takeLatest(
    callResendEmailVerification,
    function* onResendEmailVerification({ payload: { callback } }) {
      try {
        yield put(setLoading(true));
        yield call(rsf.auth.sendEmailVerification, {
          url: `${window.location.origin}/sign-in`,
        });
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message:
              'RESEND_EMAIL_NOTIFICATION.ERROR_MESSAGE.CANNOT_RESEND_EMAIL',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    },
  );
}

function* onSignOutFlow() {
  yield takeLatest(callSignOut, function* onSignOut() {
    try {
      yield put(setLoading(true));
      yield call(rsf.auth.signOut);
      yield put(setVerified2FAStatus(false));
      cookieUtil.removeVerified2FAStatus();
    } catch (error) {
      yield put(notifyError({ message: error.message, raw: true }));
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onForgotPasswordFlow() {
  yield takeLatest(callForgotPassword, function* onForgotPassword({
    payload: { emailAddress, callback },
  }) {
    try {
      yield put(setLoading(true));
      yield call(rsf.auth.sendPasswordResetEmail, emailAddress, {
        url: `${window.location.origin}/sign-in`,
      });
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'FORGOT_PASSWORD.ERROR_MESSAGE.CANNOT_SEND_EMAIL',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onUpdatePasswordFlow() {
  yield takeLatest(callUpdatePassword, function* onUpdatePassword({
    payload: { formValues, callback },
  }) {
    try {
      yield put(setLoading(true));
      const user = firebase.auth().currentUser;
      const credentials = yield call(
        firebaseAuth.EmailAuthProvider.credential,
        user.email,
        formValues.oldPassword,
      );

      yield call(rsf.auth.signInWithCredential, credentials);
      yield call(rsf.auth.updatePassword, formValues.newPassword);
      // eslint-disable-next-line no-unreachable
      yield put(
        notifySuccess({
          message: 'SETTINGS.PASSWORD.SUCCESS_MESSAGE.UPDATE_PASSWORD_SUCCESS',
        }),
      );
      callback(true);
    } catch (error) {
      // console.log(error.message);
      callback(false);
      yield put(
        notifyError({
          message: 'SETTINGS.PASSWORD.ERROR_MESSAGE.CANNOT_UPDATE_PASSWORD',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onTwoFactorGetQRCodeFlow() {
  yield takeLatest(callTwoFactorGeneration, function* onTwoFactorGetQRCode() {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }
      const response = yield call(apiUtil.authentication.generate2FACode);
      if (!response.success) {
        throw new Error(response.message);
      }
      yield put(
        setGGAuthenticator({
          qrCode: response.imageUrl,
          secretCode: response.text,
        }),
      );
    } catch (error) {
      yield call(rsf.auth.signOut);
      yield put(
        notifyError({
          message: 'TWO_FACTOR.SET_UP.ERROR_MESSAGE.CANNOT_GET_QRCODE',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onTwoFactorSetUpVerifyOTPFlow() {
  yield takeLatest(
    callTwoFactorSetUpVerifyOTP,
    function* onTwoFactorSetupVerifyOTP({ payload: { otp, callback } }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (
          !auth ||
          !auth.uid ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        const response = yield call(apiUtil.authentication.verify2FAOTP, {
          otp,
        });
        if (!response.success) {
          throw new Error(response.message);
        }
        yield put(setVerified2FAStatus(true));
        cookieUtil.setVerified2FAStatus(true);
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message: 'TWO_FACTOR.SET_UP.ERROR_MESSAGE.CANNOT_VERIFY_OTP',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    },
  );
}

function* onTwoFactorGetSecretKeyFlow() {
  yield takeLatest(
    callTwoFactorGetSecretKey,
    function* onTwoFactorGetSecretKey() {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth || !auth.uid) {
          throw new Error('Cannot get Authentication');
        }
        const snapshot = yield call(
          rsf.firestore.getDocument,
          `loginSecret/${auth.uid}`,
        );
        yield put(setBackupKey(snapshot.data().secret));
      } catch (error) {
        yield call(rsf.auth.signOut);
        yield put(
          notifyError({
            message:
              'TWO_FACTOR.SECRET_KEY.ERROR_MESSAGE.CANNOT_GET_SECRET_KEY',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    },
  );
}

function* onTwoFactorSaveSecretKeyFlow() {
  yield takeLatest(
    callTwoFactorSaveSecretKey,
    function* onTwoFactorSaveSecretKey() {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth || !auth.uid) {
          throw new Error('Cannot get Authentication');
        }
        yield call(
          rsf.firestore.setDocument,
          `users/${auth.uid}`,
          { loginSecretSaved: true, reEnableTwoFA: false },
          { merge: true },
        );
        yield put(setVerified2FAStatus(true));
        cookieUtil.setVerified2FAStatus(true);
      } catch (error) {
        yield put(
          notifyError({
            message: 'TWO_FACTOR.SET_UP.ERROR_MESSAGE.CANNOT_SAVE_BACKUP_KEY',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    },
  );
}

function* onTwoFactorVerifyOTPFlow() {
  yield takeLatest(callTwoFactorVerifyOTP, function* onTwoFactorVerifyOTP({
    payload: { otp, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }
      const response = yield call(apiUtil.authentication.verify2FAOTP, {
        otp,
      });
      if (!response.success) {
        throw new Error(response.message);
      }
      yield put(setVerified2FAStatus(true));
      cookieUtil.setVerified2FAStatus(true);
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'TWO_FACTOR.VERIFY_OTP.ERROR_MESSAGE.CANNOT_VERIFY_OTP',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onTwoFactorVerifySecretKeyFlow() {
  yield takeLatest(
    callTwoFactorVerifySecretKey,
    function* onTwoFactorVerifySecretKey({ payload: { secretKey, callback } }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (
          !auth ||
          !auth.uid ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        const response = yield call(apiUtil.authentication.verify2FASecretKey, {
          key: secretKey,
        });
        if (!response.success) {
          throw new Error(response.message);
        }
        yield call(
          rsf.firestore.setDocument,
          `users/${auth.uid}`,
          { loginSecretSaved: false, reEnableTwoFA: true },
          { merge: true },
        );
        yield put(setVerified2FAStatus(false));
        cookieUtil.setVerified2FAStatus(false);
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message:
              'TWO_FACTOR.VERIFY_SECRET_KEY.ERROR_MESSAGE.CANNOT_VERIFY_OTP',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    },
  );
}

function* onUpdatePrimaryEmail() {
  yield takeLatest(callUpdatePrimaryEmail,
    function* onUpdateEmail({
      payload: { email, callback = () => {} },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);

        if (!auth || !auth.uid) {
          throw new Error('Cannot get Authentication');
        }
        const response = yield call(
          apiUtil.authentication.updatePersonalEmail,
          { email },
        );
        if (!response.success) {
          throw new Error(response.message);
        }
        yield put(
          notifySuccess({
            message: response.message,
            raw: true,
          }),
        );
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message: error.message,
            raw: true,
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onUpdateInformationFlow() {
  yield takeLatest(callUpdateInformation, function* onUpdateInformation({
    payload: { formValues, callback = () => {} },
  }) {
    try {
      let downloadUrl;
      let updatedValues;

      yield put(setLoading(true));
      const auth = yield select(getAuth);
      const user = yield select(getCurrentUserProfile);

      if (!auth || !auth.uid) {
        throw new Error('Cannot get Authentication');
      }
      let defaultWalletSettingConfirmed = false;
      if (user &&
        Object.prototype.hasOwnProperty.call(
          user, 'defaultWalletSettingConfirmed',
        ) &&
        user.defaultWalletSettingConfirmed) {
        defaultWalletSettingConfirmed =
          user.defaultWalletBalanceToBeDeducted
            !== formValues.defaultWalletBalanceToBeDeducted ?
            false : user.defaultWalletSettingConfirmed;
      }
      if (formValues.imageAsFile) {
        yield call(rsf.storage.uploadFile,
          `profileImages/${auth.uid}`,
          formValues.imageAsFile);
        downloadUrl = yield call(rsf.storage
          .getDownloadURL, `profileImages/${auth.uid}`);
        updatedValues = {
          image: downloadUrl,
          companyName: formValues.companyName,
          defaultCurrency: formValues.defaultCurrency,
          defaultWalletBalanceToBeDeducted:
            formValues.defaultWalletBalanceToBeDeducted,
          defaultWalletSettingConfirmed,
        };
      } else {
        updatedValues = {
          companyName: formValues.companyName,
          defaultCurrency: formValues.defaultCurrency,
          defaultWalletBalanceToBeDeducted:
            formValues.defaultWalletBalanceToBeDeducted,
          defaultWalletSettingConfirmed,
        };
      }

      yield call(
        rsf.firestore.setDocument,
        `users/${auth.uid}`,
        updatedValues,
        { merge: true },
      );

      // update email
      yield put(
        notifySuccess({
          message:
            'SETTINGS.PERSONAL.SUCCESS_MESSAGE.UPDATE_INFORMATION_SUCCESS',
        }),
      );
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'SETTINGS.PERSONAL.ERROR_MESSAGE.CANNOT_UPDATE_INFORMATION',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onTwoFactorDisableFlow() {
  yield takeLatest(callTwoFactorDisable, function* onTwoFactorDisable({
    payload: { otp, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }
      const response = yield call(apiUtil.authentication.twoFactorDisable, {
        otp,
      });
      if (!response.success) {
        throw new Error(response.message);
      }
      yield put(
        notifySuccess({
          message: 'SETTINGS.SECURITY.DISABLED_SUCCESS',
        }),
      );
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'TWO_FACTOR.VERIFY_OTP.ERROR_MESSAGE.CANNOT_VERIFY_OTP',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onResendEmailOtpFlow() {
  yield takeLatest(callResendEmailOtp, function* onResendOEmailOtp({
    payload: { callback, amount, unit },
  }) {
    try {
      yield put(setLoading(true));

      const response = yield call(apiUtil.authentication.generate2FAEmailCode, {
        amount,
        unit,
      });
      if (!response.success) {
        throw new Error(response.message);
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(notifyError({ message: error.message, raw: true }));
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onVerifyEmailOtpFlow() {
  yield takeLatest(callVerifyEmailOtp, function* onVerifyEmailOtp({
    payload: { callback, otp },
  }) {
    try {
      yield put(setLoading(true));

      const response = yield call(apiUtil.authentication.verifyEmailOTP, {
        otp,
      });

      if (!response.success) {
        throw new Error(response.message);
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(notifyError({ message: error.message, raw: true }));
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onResetPasswordFlow() {
  yield takeLatest(callResetPassword, function* onResetPassword({
    payload: { oobCode, email, password, callback },
  }) {
    try {
      yield put(setLoading(true));
      const credentials = yield call(
        firebaseAuth.EmailAuthProvider.credential,
        email,
        password,
      );
      try {
        yield call(rsf.auth.signInWithCredential, credentials);
        callback(false, true);
        yield call(rsf.auth.signOut);
        yield put(setVerified2FAStatus(false));
        cookieUtil.removeVerified2FAStatus();
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          yield call(rsf.auth.confirmPasswordReset, oobCode, password);
          callback(true);
        }
      }
    } catch (error) {
      callback(false);
      yield put(notifyError({ message: error.message, raw: true }));
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onAddEmailNotificationFlow() {
  yield takeLatest(callAddEmailNotification, function* onAddEmailNotification({
    payload: { email, callback = () => {} },
  }) {
    try {
      yield put(setLoading(true));
      const response = yield call(apiUtil.authentication.addEmailNotification, {
        email,
      });
      if (!response.success) {
        throw new Error(response.message);
      } else {
        yield put(notifySuccess({
          message: response.message,
          raw: true,
        }));
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(notifyError({ message: error.message, raw: true }));
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onEditEmailNotificationFlow() {
  yield takeLatest(callEditEmailNotification, function* onAddEmailNotification({
    payload: { email, payments, withdrawals, others, callback = () => {} },
  }) {
    try {
      yield put(setLoading(true));
      const response = yield call(apiUtil.authentication.editEmailNotification,
        {
          email,
          others,
          withdrawals,
          payments,
        });
      if (!response.success) {
        throw new Error(response.message);
      } else {
        yield put(notifySuccess({
          message: response.message,
          raw: true,
        }));
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(notifyError({ message: error.message, raw: true }));
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onDeleteEmailNotificationFlow() {
  yield takeLatest(callDeleteEmailNotification,
    function* onDeleteEmailNotification({
      payload: { emails, callback = () => {} },
    }) {
      try {
        yield put(setLoading(true));
        const response = yield call(
          apiUtil.authentication.deleteEmailNotification,
          {
            emails,
          },
        );
        if (!response.success) {
          throw new Error(response.message);
        } else {
          yield put(notifySuccess({
            message: response.message,
            raw: true,
          }));
        }
        callback(true);
      } catch (error) {
        callback(false);
        yield put(notifyError({ message: error.message, raw: true }));
      } finally {
        yield put(setLoading(false));
      }
    });
}

export default function* saga() {
  yield all([
    onInitializeAuthFlow(),
    onSignInFlow(),
    onSignUpFlow(),
    onSignOutFlow(),
    onForgotPasswordFlow(),
    onUpdatePasswordFlow(),
    onTwoFactorGetQRCodeFlow(),
    onTwoFactorSetUpVerifyOTPFlow(),
    onTwoFactorGetSecretKeyFlow(),
    onTwoFactorSaveSecretKeyFlow(),
    onTwoFactorVerifyOTPFlow(),
    onTwoFactorVerifySecretKeyFlow(),
    onResendEmailVerificationFlow(),
    onSignUpResendEmailVerificationFlow(),
    onUpdateInformationFlow(),
    onTwoFactorDisableFlow(),
    onResetPasswordFlow(),
    onResendEmailOtpFlow(),
    onVerifyEmailOtpFlow(),
    onUpdatePrimaryEmail(),
    onDeleteEmailNotificationFlow(),
    onEditEmailNotificationFlow(),
    onAddEmailNotificationFlow(),
  ]);
}
