import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import {
  getVerified2FAStatus,
  getCurrentUserProfile,
} from '../../redux/ducks/auth.duck';

/**
 * @function withVerifiedTwoFactor
 * @param {*} Component
 * @description Allow user who already verified Two-factor Authentication access component
 */
const withVerifiedTwoFactor = (Component) => (props) => {
  const user = useSelector(getCurrentUserProfile, shallowEqual);
  const verified2FA = useSelector(getVerified2FAStatus);

  if (isLoaded(user)) {
    if (!isEmpty(user)) {
      // console.log('withVerifiedTwoFactor');
      if (user.twoFactorSetup && !verified2FA) {
        return <Redirect to="/two-factor/verify-otp" />;
      }
    }
  }

  return <Component {...props} />;
};

export default withVerifiedTwoFactor;
