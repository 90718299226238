/* eslint-disable max-len */
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';

import tabs from './WithdrawalsTabs/withdrawalsTabs.duck';
import lnurl from './WithdrawBTC/withdrawLNURL.duck';
import historyTabs from './WithdrawalsHistoryTabs/WithdrawalsHistoryTabs.duck';
import transactions from './ListTransactions/listTransactions.duck';
import convertHistory from './HistoryTransactions/historyTransactions.duck';

const PREFIX = 'DASHBOARD/WITHDRAWALS';

export const callWithdrawLnurl = createAction(`${PREFIX}/WITHDRAW/LNURL`);
export const callWithdrawVerifyOTP = createAction(`${PREFIX}/WITHDRAW/VERIFY_OTP`);
export const callWithdrawCash = createAction(`${PREFIX}/CREATE_WITHDRAW_CASH`);
export const callWithdrawBTC = createAction(`${PREFIX}/CREATE_WITHDRAW_BTC`);
export const callWithdrawStableCoin = createAction(`${PREFIX}/CREATE_WITHDRAW_STABLECOIN`);

export default combineReducers({
  tabs,
  lnurl,
  transactions,
  historyTabs,
  convertHistory,
});
