/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';
import moment from 'moment';

import { PAYMENT_STATUS } from '../../constants/USER';
import { bitcoinUtil, currencyUtil } from '../../utils';

const PREFIX = 'DASHBOARD/PAYMENTS';
const defaultSearchCriteria = {
  page: 1, // TODO integration: check with API param/query
  limit: 10, // TODO integration: check with API param/query - row per page
  status: 'all', // TODO integration: check with API param/query
  time: undefined, // TODO integration: check with API param/query
  search: '', // TODO integration: check with API param/query
};
const initialStates = {
  isFetching: false,
  // Currently, all data are pulled and search creteria will be done in FE
  // If we change to search via API
  // just remove `allData` and everthing relate, use only `list`
  allData: [],
  list: [],
  csv: [],
  totalRecords: 0,
  searchCriteria: defaultSearchCriteria,
  paymentDetailsModal: { open: false, details: undefined },
};

export const callGetListPayments = createAction(`${PREFIX}/GET_LIST_PAYMENTS`);
export const callDownloadPayments = createAction(`${PREFIX}/DOWNLOAD_PAYMENTS`);
export const callUpdateListPayments = createAction(`${PREFIX}/UPDATE_LIST_PAYMENTS`);

export const resetSearchCriteria = createAction(`${PREFIX}/RESET_SEARCH_CRITERIA`);
export const setPaymentsData = createAction(`${PREFIX}/SET_LIST_DATA`);
export const setPaymentsDetailsModal = createAction(`${PREFIX}/SET_PAYMENT_DETAILS_MODAL`);

export const getAllPayments = (state) => state.payments.allData;
export const getListPayments = (state) => state.payments.list;
export const getPaymentsCSV = (state) => state.payments.csv;
export const getFetching = (state) => state.payments.isFetching;
export const getTotalRecords = (state) => state.payments.totalRecords;
export const getSearchCriteria = (state) => state.payments.searchCriteria;
export const getPaymentDetailsModal = (state) => state.payments.paymentDetailsModal;

// Currently, all data are pulled and search creteria will be done in FE
// If we change to search via API, just remove this and everthing relate
export const filterPayments = (data, criteria = defaultSearchCriteria) => {
  if (!data || data.length === 0) return [];

  let result = [...data];
  const { page, limit, status, time, search } = criteria;

  // Status
  if (status !== PAYMENT_STATUS.ALL) {
    result = result.filter(item => item.invoice_status === status);
  }

  // Time
  if (time) {
    const today = Date.now();
    switch (time) {
      case 'lastWeek': {
        const lastSevenDays = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastSevenDays / 1000);
        break;
      }
      case 'lastMonth': {
        const lastThirtyDays = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastThirtyDays / 1000);
        break;
      }
      default: {
        if (typeof time === 'object' && time.from && time.to) {
          // console.log(time.from.getTime() / 1000, time.to.getTime() / 1000);
          result = result.filter(item =>
            item.created_at >= time.from.getTime() / 1000 &&
            item.created_at <= time.to.getTime() / 1000);
        }
        break;
      }
    }
  }
  // Search
  if (search) {
    result = result.filter(
      (item) =>
        (item.description &&
        item.description.toLowerCase().includes(search.toLowerCase())) ||
        (item.created_at_formated &&
        item.created_at_formated.toLowerCase().includes(search.toLowerCase())),
    );
  }

  const totalRecordsAfterFiltered = result.length;
  // Paging
  // h2o: OMFG why the fuck do we do another copy paste for the EXACT SAME FUCKING FORMATTING
  const csvData = result && result.length > 0 ? result.map(item => {
    const currencyFormatter = (amount) => `${currencyUtil.formatByUnit(amount, item.currency)} ${item.currency}`;
    const formatPrice = (value) => {
      if (bitcoinUtil.isCryptoCurrency(item.currency)) {
        return bitcoinUtil.formatByUnit(true, value, item.currency);
      }
      return currencyFormatter(value);
    };
    const method = item.method ? item.method : item.currency;
    const fee = formatPrice((item.txPlatformFee / 100000000) * item.exchangeRate);
    let unitSale = `${currencyUtil.formatByUnit(item.unitSale)} ${method}`;
    let unitTotal = `${currencyUtil.formatByUnit((item.priceLessPlatformFee / 100000000) * item.exchangeRate)} ${method}`;
    let total = (item.priceLessPlatformFee / 100000000) * item.exchangeRate;
    if (item.invoice_status !== 'unpaid' && item.invoice_status !== 'expired') {
      if (item.paidVia !== 'stable-coin') {
        unitTotal = Number((item.priceLessPlatformFee / 100000000).toFixed(8));
        unitTotal = `${unitTotal} BTC`;
        unitSale = Number((item.price / 100000000).toFixed(8));
        unitSale = `${unitSale} BTC`;
      }
    } else {
      unitSale = '';
      unitTotal = '';
      total = '';
    }
    let address = null;
    if (item.invoice_status !== 'expired') {
      if (item.paidVia === 'on-chain') {
        address = item.stablecoinsERC20 &&
        Object.prototype.hasOwnProperty.call(item.chainInvoice, 'address') &&
        item.stablecoinsERC20.address ?
          item.stablecoinsERC20.address : null;
      } else if (item.paidVia === 'stable-coin') {
        address = item.stablecoinsERC20 &&
          Object.prototype.hasOwnProperty.call(item.stablecoinsERC20, 'address') &&
          item.stablecoinsERC20.address ?
          item.stablecoinsERC20.address : null;
      }
    }
    return {
      time: moment(item.created_at * 1000).format('MMM D, YYYY h:mm a'),
      name: item.description,
      status: item.invoice_status,
      fee,
      sale: formatPrice(item.unitSale),
      method: item.paidVia,
      unitSale,
      total,
      unitTotal,
      instantConvert: item.instantSats > 0,
      address,
      currency: item.currency,
    };
  }) : [];
  result = result.slice((page - 1) * limit, page * limit);

  // Format
  const formattedResult = result && result.length > 0 ? result.map(item => {
    let address = null;
    if (item.invoice_status !== 'expired') {
      if (item.paidVia === 'on-chain') {
        address = item.chainInvoice &&
        Object.prototype.hasOwnProperty.call(item.chainInvoice, 'address') &&
        item.chainInvoice.address ?
          item.chainInvoice.address : null;
      } else if (item.paidVia === 'stable-coin') {
        address = item.stablecoinsERC20 &&
          Object.prototype.hasOwnProperty.call(item.stablecoinsERC20, 'address') &&
          item.stablecoinsERC20.address ?
          item.stablecoinsERC20.address : null;
      }
    }
    const formatPrice = (value, isCompacted = true) => {
      if (bitcoinUtil.isCryptoCurrency(item.currency)) {
        return bitcoinUtil.formatByUnit(true, value, item.currency, isCompacted);
      }
      return `${currencyUtil.formatByUnit(value, item.currency, isCompacted)} ${
        currencyUtil.isDollar(item.currency) ? item.currency : ''}`;
    };
    const btcUnitFormatter = (amount) => {
      const btcThreshold = 0.00999999;
      const convertedAmount = amount / 100000000;
      let unit;
      if (convertedAmount <= btcThreshold) {
        // Convert BTC to Sats
        unit = bitcoinUtil.convert(
          Number(bitcoinUtil.formatBTC(convertedAmount)), 'BTC', 'sats',
        );
        return `${bitcoinUtil.formatByUnit(true, unit, 'SATS', true)}`;
      }
      return `${Number(bitcoinUtil.formatBTC(convertedAmount))} BTC`;
    };

    const method = item.method ? item.method : item.currency;
    const fee = formatPrice((item.txPlatformFee / 100000000) * item.exchangeRate);
    const fullFee = formatPrice((item.txPlatformFee / 100000000) * item.exchangeRate, false);
    let unitSale = `${currencyUtil.formatByUnit(item.unitSale)} ${method}`;
    let unitTotal = `${currencyUtil.formatByUnit((item.priceLessPlatformFee / 100000000) * item.exchangeRate)} ${method}`;
    let total = (item.priceLessPlatformFee / 100000000) * item.exchangeRate;
    let txnHash = null;
    if (Object.prototype.hasOwnProperty.call(item, 'paidVia') && (item.status === 'paid' ||
      item.status === 'expired-paid' || item.status === 'expired-partially-paid'
    )) {
      if (item.paidVia === 'on-chain') {
        txnHash = Object.prototype.hasOwnProperty.call(item, 'chainInvoice')
        && item.chainInvoice
        && Object.prototype.hasOwnProperty.call(item.chainInvoice, 'tx_hash')
        && item.chainInvoice.tx_hash
          ? item.chainInvoice.tx_hash : null;
      } else if (item.paidVia === 'stable-coin') {
        txnHash = Object.prototype.hasOwnProperty.call(item, 'stablecoinsERC20')
        && item.stablecoinsERC20
        && Object.prototype.hasOwnProperty.call(item.stablecoinsERC20, 'address')
        && item.stablecoinsERC20.address
          ? item.stablecoinsERC20.address : null;
      }
    }
    if (item.invoice_status !== 'unpaid' && item.invoice_status !== 'expired') {
    // if (item.invoice_status === 'complete' ||
    //  item.invoice_status === 'expired-paid' ||
    //  item.invoice_status === 'partially-paid') {
      const isBitcoins = item.paidVia === 'on-chain' || item.paidVia === 'lightning';
      const isPartially = item.invoice_status === 'partially-paid';
      if (isBitcoins) {
        unitTotal = item.priceLessPlatformFee;
        unitTotal = btcUnitFormatter(unitTotal);
        unitSale = btcUnitFormatter(item.price);
        if (item.paidVia === 'on-chain') {
          txnHash = Object.prototype.hasOwnProperty.call(item, 'chainInvoice')
            && item.chainInvoice
            && Object.prototype.hasOwnProperty.call(item.chainInvoice, 'tx_hash')
            && item.chainInvoice.tx_hash
            ? item.chainInvoice.tx_hash : null;
        }
      } else {
        const calStabFee = ((item.stablecoinsERC20.price * 1) / 100);
        unitTotal = isPartially ?
          parseFloat(item.stablecoinsERC20.price - calStabFee) : item.priceLessPlatformFeeSc;
        unitTotal = `${Number(unitTotal).toFixed(2)} ${method}`;
        // unitSale = `${currencyUtil.formatByUnit(item.stablecoinsERC20.price)} ${method}`;
        unitSale = `${currencyUtil.formatByUnit(item.stablecoinsERC20.price)} ${method}`;
        txnHash = Object.prototype.hasOwnProperty.call(item, 'stablecoinsERC20')
          && item.stablecoinsERC20
          && Object.prototype.hasOwnProperty.call(item.stablecoinsERC20, 'address')
          && item.stablecoinsERC20.address
          ? item.stablecoinsERC20.address : null;
      }
    } else {
      unitSale = '';
      unitTotal = '';
      total = '';
    }

    return {
      refId: item.id,
      time: moment(new Date(item.created_at * 1000)).format('MMM D, YYYY h:mm a'),
      name: item.description,
      status: item.invoice_status,
      fee,
      fullFee,
      // sale: `${currencyUtil.formatByUnit(item.unitSale, item.currency)} ${item.currency}`,
      sale: formatPrice(item.unitSale),
      fullSale: formatPrice(item.unitSale, false),
      method: item.paidVia,
      unitSale,
      // total: `${currencyUtil.formatByUnit((item.priceLessPlatformFee / 100000000) * item.exchangeRate, item.currency)} ${item.currency}`,
      total: formatPrice(total),
      fullTotal: formatPrice(total, false),
      unitTotal,
      instantConvert: item.instantSats > 0,
      currency: item.currency,
      txnHash,
      address,
      // stablecoinsERC20Address: Object.prototype.hasOwnProperty.call(item, 'paid_via')
    };
  }) : [];

  return {
    list: formattedResult,
    csv: csvData,
    totalRecords: totalRecordsAfterFiltered,
  };
};

export default handleActions(
  new Map([
    [
      setPaymentsData,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
    [
      resetSearchCriteria,
      (state) => ({
        ...state,
        searchCriteria: defaultSearchCriteria,
      }),
    ],
    [
      setPaymentsDetailsModal,
      (state, { payload }) => ({
        ...state,
        paymentDetailsModal: { ...payload },
      }),
    ],
  ]),
  { ...initialStates },
);
