import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import { getCurrentUserProfile } from '../../redux/ducks/auth.duck';

/**
 * @function withUsedSecretKey
 * @param {*} Component
 * @description Allow user who already used Two-factor Secret Key access component to get a new one
 */
const withUsedSecretKey = (Component) => (props) => {
  const user = useSelector(getCurrentUserProfile, shallowEqual);

  if (isLoaded(user)) {
    if (!isEmpty(user)) {
      if (user.reEnableTwoFA) {
        return <Component {...props} />;
      }

      return <Redirect to="/dashboard" />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <Loading open />;
};

export default withUsedSecretKey;
