import { apiService, qrService } from '../services';
import { firebaseAuth } from '../services/firebase';

const authentication = {
  generate2FACode: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('twoFactorGenerate', {
      ...body,
      token,
    });
  },
  generate2FAEmailCode: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('sendTwoFactorEmail', {
      ...body,
      token,
    });
  },
  verifyEmailOTP: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('verifyTwoFAEmail', {
      ...body,
      token,
    });
  },
  verify2FAOTP: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('verifyUserTwoFA', {
      ...body,
      token,
    });
  },
  verify2FASecretKey: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('verifySecretKey', {
      ...body,
      token,
    });
  },
  twoFactorDisable: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('twoFactorDisable', {
      ...body,
      token,
    });
  },
  addEmailNotification: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('addEmailNotification', {
      ...body,
      token,
    });
  },
  editEmailNotification: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('editEmailNotification', {
      ...body,
      token,
    });
  },
  confirmEmailNotification: async (body) => apiService.post(
    'confirmEmailNotification', {
      ...body,
    },
  ),
  deleteEmailNotification: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('deleteEmailNotification', {
      ...body,
      token,
    });
  },
  updatePersonalEmail: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('updatePersonalEmail', {
      ...body,
      token,
    });
  },
  userConfirmEmailUpdate: (body) =>
    apiService.post('userConfirmEmailUpdate', body),
};

const bitcoin = {
  getRate: (currency) => apiService.get(`rate/${currency}`),
  // eslint-disable-next-line max-len
  getTxEstimatedFee: (currency) => apiService.get(`getTxEstimatedFee/${currency}`),
  getBaseRate: (currency) => apiService.get(`baseRate/${currency}`),
};

const donation = {
  createTransaction: (body) => apiService.post('donation', body),
  info: (code) => apiService.get(`donationInfo/${code}`),
  createInvoice: (body) => apiService.post('donationInvoice', body),
};

const user = {
  getWalletBalance: async () => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.get('walletBalance', undefined, {
      headers: {
        token,
      },
    });
  },
  // getListTransactions: (body) => apiService.get('transactions', body),
  convertBTCToCash: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('convertBtcToCash', {
      ...body,
      token,
    });
  },
  convertStablecoinToCash: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('convertStablecoinToCash', {
      ...body,
      token,
    });
  },
  convertBTCToStablecoin: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('convertBTCToStablecoin', body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
};

const invoice = {
  quickInvoice: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('quickInvoice', body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
};

const checkout = {
  getInfo: (transactionId) => apiService.get(`info/${transactionId}`),
  getStatus: (transactionId) => apiService.get(`status/${transactionId}`),
};

const kyc = {
  sendRequest: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('kycInfo', body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
  },
};

const qrCode = {
  generate: (body) => qrService.post('custom', body),
};

const withdrawal = {
  cash: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('cashWithdrawal', {
      ...body,
      token,
    });
  },
  lnurl: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('withdrawLnurl', {
      ...body,
      token,
    });
  },
  lightning: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('sendPayment', {
      ...body,
      token,
    });
  },
  stablecoin: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('sendTokens', {
      ...body,
      token,
    });
  },
  onChain: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('sendCoins', {
      ...body,
      token,
    });
  },
  confirmCash: (body) => apiService.post('userConfirmCashWithdrawal', body),
  confirm: (body) => apiService.post('userConfirmWithdrawal', body),
};

const payouts = {
  createRequest: async (body) => {
    const token = await firebaseAuth().currentUser.getIdToken(true);
    return apiService.post('mass-payouts', {
      ...body,
      token,
    });
  },
  confirm: (body) => apiService.post('userConfirmPayouts', body),
};

export default {
  authentication,
  user,
  bitcoin,
  donation,
  invoice,
  checkout,
  kyc,
  qrCode,
  withdrawal,
  payouts,
};
