import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import {
  getCurrentUserProfile,
  getVerified2FAStatus,
} from '../../redux/ducks/auth.duck';

/**
 * @function withoutSetUpTwoFactor
 * @param {*} Component
 * @description Allow user who has not set up Two-factor Authentication access component
 */
const withoutSetUpTwoFactor = (Component) => (props) => {
  const user = useSelector(getCurrentUserProfile, shallowEqual);
  const verified2FA = useSelector(getVerified2FAStatus);
  const [disabled2FALogin] = useState(true);

  if (isLoaded(user)) {
    if (!isEmpty(user)) {
      if (!disabled2FALogin && !user.loginSecretSaved) {
        return <Component {...props} />;
      }

      if (!verified2FA) {
        return <Redirect to="/two-factor/verify-otp" />;
      }

      return <Redirect to="/dashboard" />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <Loading open />;
};

export default withoutSetUpTwoFactor;
