import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/WITHDRAWALS/TABS';
const initialStates = {
  selectedHistoryTab: {
    index: 0,
    data: {},
  },
};

// eslint-disable-next-line max-len
export const setSelectedHistoryTab = createAction(`${PREFIX}/SET_SELECTED_HISTORY_TAB`);

// eslint-disable-next-line max-len
export const getSelectedHistoryTab = (state) => state.withdrawals.historyTabs.selectedHistoryTab;

export default handleActions(
  new Map([
    [
      setSelectedHistoryTab,
      (state, { payload }) => ({
        ...state,
        selectedHistoryTab: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
