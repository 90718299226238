import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'CART';
const initialStates = {
  items: [],
  totalAmt: 0.00,
  coupons: [],
  currency: 'USD',
  otherCurrency: 'USD',
  exchangeRate: 0,
};
/*
{
      id: 1212122121,
      type: 'percent',
      quantity: 1,
      value: 5,
      name: 'COUPONCODE',
      price: 500,
    },
*/
export const setCartTotal = createAction(`${PREFIX}/SET_CART_TOTAL`);
export const setCartItems = createAction(`${PREFIX}/SET_ITEM`);
export const setCartCurrency = createAction(`${PREFIX}/SET_CART_CURRENCY`);
export const setCartOtherCurrency = createAction(
  `${PREFIX}/SET_CART_OTHER_CURRENCY`,
);
export const setExchangeRate = createAction(`${PREFIX}/SET_EXCHANGE_RATE`);

export const callConvertPrice = createAction(`${PREFIX}/CONVERT_PRICE`);

export const addCartItem = createAction(`${PREFIX}/ADD_CART_ITEM`);
export const removeCartItem = createAction(`${PREFIX}/REMOVE_CART_ITEM`);

export const callSaveCart = createAction(`${PREFIX}/SET_CART_ITEMS`);

export const setCartDiscount = createAction(`${PREFIX}/SET_DISCOUNTS`);
export const addCartDiscount = createAction(`${PREFIX}/ADD_DISCOUNTS`);
export const removeCartDiscount = createAction(`${PREFIX}/REMOVE_DISCOUNTS`);

export const getCartItems = (state) => state.cart.items;
export const getCartItemsTotal = (state) => state.cart.totalAmt;
export const getCartCurrency = (state) => state.cart.currency;
export const getCartOtherCurrency = (state) => state.cart.otherCurrency;
export const getCartCoupons = (state) => state.cart.coupons;
export const getCartExchangeRate = (state) => state.cart.exchangeRate;

export default handleActions(
  new Map([
    [
      setCartItems,
      (state, { payload }) => ({
        ...state,
        items: payload,
      }),
    ],
    [
      setCartTotal,
      (state, { payload }) => ({
        ...state,
        totalAmt: payload,
      }),
    ],
    [
      setCartCurrency,
      (state, { payload }) => ({
        ...state,
        currency: payload,
      }),
    ],
    [
      setCartOtherCurrency,
      (state, { payload }) => ({
        ...state,
        otherCurrency: payload,
      }),
    ],
    [
      setCartDiscount,
      (state, { payload }) => ({
        ...state,
        coupons: payload,
      }),
    ],
    [
      setExchangeRate,
      (state, { payload }) => ({
        ...state,
        exchangeRate: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
