/* eslint-disable */
import moment from 'moment';
import MONTHS from '../constants/MONTHS';

const isToday = (day, month, year) => {
  const date = moment();
  return day === date.date() && month === MONTHS[date.month()] && year === date.year();
};

const isYesterday = (day, month, year) => {
  const date = moment().subtract(1, 'day');
  return day === date.date() && month === MONTHS[date.month()] && year === date.year();
};

/**
	* @param {string} date The input must be month (Jan, Feb...)
	* @returns Month (Jan) to Month Index (01)
	*/
const convertToMonthIndex = (month) => (MONTHS.indexOf(month) + 1 >= 10 ? `${MONTHS.indexOf(month) + 1}` : `0${MONTHS.indexOf(month) + 1}`);

/**
	* @param {string} date The input must be the UNIX time: 1454521239279
	* @param {string} format DD-MM-YYYY HH:mm
	* @returns Based on the given format
	*/
const convertUnixTimeToDate = (timestamp, format) => moment(timestamp).format(format);

/**
	* @param {string} date The input must be formatted as 2017-08-30T00:00:00
	* @returns The formatted weekday is Wed, Tue, Mon...
	*/
const formatShortWeekDay = (date) => moment(date).format('ddd');

/**
	* @param {string} date The input must be formatted as 2017-08-30T00:00:00
	* @returns The formatted weekday is Wednesday, Tuesday, Monday...
	*/
const formatFullWeekDay = (date) => moment(date).format('dddd');

const isLastSevenDays = ({day, month, year}) => {
  const start = moment().subtract(8, 'days');
  const end = moment().add(1, 'day');
  const comparedDate = moment(
    `${day}/${month}/${year}`,
    'DD/MM/YYYY',
  );

		return comparedDate.isBetween(start,end, '[]')
};

export default {
  isToday,
  isYesterday,
		isLastSevenDays,
  convertToMonthIndex,
  convertUnixTimeToDate,
  formatFullWeekDay,
  formatShortWeekDay,
};
