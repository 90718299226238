import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import { getAuth } from '../../redux/ducks/auth.duck';

/**
 * @function withAuth
 * @param {*} Component
 * @description Allow user who already loggedin access component
 */
const withAuth = (Component) => (props) => {
  const auth = useSelector(getAuth, shallowEqual);

  if (isLoaded(auth)) {
    if (!isEmpty(auth)) {
      return <Component {...props} />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <Loading open />;
};

export default withAuth;
