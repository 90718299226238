import { createUseStyles } from 'react-jss';

import BREAKPOINTS from './constants/BREAKPOINTS';
import COLORS from './constants/COLORS';

export default createUseStyles({
  '@global': {
    html: { fontSize: 16 },
    body: {
      margin: 0,
      fontFamily: 'Poppins, sans-serif !important',
      color: COLORS.textDarkBlack,
    },
  },

  [`@media only screen and (max-width: ${BREAKPOINTS.maxXXl}px)`]: {
    '@global': { html: { fontSize: 13 } },
  },
  [`@media only screen and (max-width: ${BREAKPOINTS.maxXl}px)`]: {
    '@global': { html: { fontSize: 12 } },
  },
  [`@media only screen and (max-width: ${BREAKPOINTS.maxSm}px)`]: {
    '@global': { html: { fontSize: 16 } },
  },
});
