import createSagaMiddleware from 'redux-saga';
import { intlReducer } from 'react-intl-redux';
import { firebaseReducer } from 'react-redux-firebase';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

import history from '../history';
import messages from '../intl';
import { localstorageUtil } from '../utils';
import { DEFAULT_LOCALE } from '../configs';

import rootSagas from './sagas';
import rootDucks from './ducks';

const locale = localstorageUtil.getLocale() || DEFAULT_LOCALE;

/**
 * Initial redux saga state
 */
const initialState = {
  intl: { locale, messages: messages[locale] },
};
/**
 * More infomation read here
 * https://redux-saga.js.org/docs/api/#createsagamiddlewareoptions
 */
const sagaMiddleware = createSagaMiddleware();
/**
 * Using Redux DevTools Extension to debug Redux's state on browser
 * https://github.com/zalmoxisus/redux-devtools-extension
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/**
 * Add external middlewares connect to Redux store
 */
const middlewares = [routerMiddleware(history), sagaMiddleware];

const appReducer = combineReducers({
  intl: intlReducer,
  router: connectRouter(history),
  firebase: firebaseReducer,
  ...rootDucks,
});
const rootReducers = (state, action) => {
  if (action.type === 'AUTH/SIGN_OUT') {
    localStorage.clear();
    const { intl } = state;
    // eslint-disable-next-line no-param-reassign
    state = { intl };
  }
  return appReducer(state, action);
};
/**
 * Redux store
 */
const store = createStore(
  rootReducers,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares)),
);

// start middleware
sagaMiddleware.run(rootSagas);

export default store;
