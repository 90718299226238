import { QR_API_URL, QR_API_KEY, FETCHING_TIMEOUT } from '../configs';

const buildURL = (path) =>
  // eslint-disable-next-line no-useless-escape
  [QR_API_URL.replace(/[\/]+$/, ''), path.replace(/[\/]+$/, '')].join('/');

const generateOptions = (customOptions) => {
  const defaultOptions = {
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      useQueryString: true,
      'x-rapidapi-key': QR_API_KEY,
    },
  };

  return { ...defaultOptions, ...customOptions };
};

const fetchWithTimeout = (args, timeout = FETCHING_TIMEOUT) =>
  Promise.race([
    fetch(...args),
    new Promise((_, rj) => {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        rj(new Error(`Fetching timeout in ${timeout} ms`));
      }, timeout);
    }),
  ]);

const handleResponse = (response) => {
  if (response.redirected && response.url) {
    return Promise.resolve(response);
  }

  if (!response.ok) {
    return Promise.reject(response);
  }

  if (response.status === 204) {
    return Promise.resolve(response);
  }

  return response.json();
};

/**
 * @function apiPost
 * @description Make a POST request.
 * @param {string} path
 * @param {object} body
 * @param {object} options
 * @returns {promise}
 */
const apiPost = (
  path,
  body,
  options = { keepBody: false, timeout: undefined },
) =>
  fetchWithTimeout(
    [
      buildURL(path),
      generateOptions({
        ...options,
        method: 'POST',
        body: !options.keepBody ? JSON.stringify(body) : body,
      }),
    ],
    options.timeout,
  )
    .then(handleResponse)
    .catch(handleResponse);

export default {
  post: apiPost,
};
