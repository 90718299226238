/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';
import { dateUtil } from '../../utils';

const PREFIX = 'DASHBOARD/BALANCES';
const defaultSearchCriteria = {
  page: 1, // TODO integration: check with API param/query
  limit: 10, // TODO integration: check with API param/query - row per page
  time: undefined, // TODO integration: check with API param/query
  search: '', // TODO integration: check with API param/query
};

const initialStates = {
  isFetching: false,
  // TODO check search on BE or FE then remove or not remove `allData`
  // Currently, all data are pulled and search creteria will be done in FE
  // If we change to search via API
  // just remove `allData` and everthing relate, use only `list`
  allData: [],
  cashData: [],
  btcData: [],
  usdtData: [],
  busdData: [],
  usdcData: [],
  list: [],
  csvData: [],
  tab: 0,
  totalRecords: 0,
  searchCriteria: defaultSearchCriteria,
};

export const callGetTransactions = createAction(`${PREFIX}/GET_TRANSACTIONS`);
export const callGetIncomeRateFromSales = createAction(`${PREFIX}/GET_INCOME_RATE_FROM_SALES`);

export const setTransactionsData = createAction(`${PREFIX}/SET_DATA`);
export const setIncomeRateData = createAction(`${PREFIX}/SET_INCOME_RATE`);

// TODO: remove `getTransactions` if no need
export const getSearchCriteria = (state) => state.balances.searchCriteria;
export const getTransactions = (state) => state.balances.allData;
export const getFetching = (state) => state.balances.isFetching;
export const getCashIncomeRate = (state) => state.balances.cashIncomeRate;
export const getTetherIncomeRate = (state) => state.balances.usdtIncomeRate;
export const getBinanceIncomeRate = (state) => state.balances.busdIncomeRate;
export const getUSDCIncomeRate = (state) => state.balances.usdcIncomeRate;
export const getBitcoinIncomeRate = (state) => state.balances.btcIncomeRate;
export const getCashData = (state) =>
  state.balances.cashData.map((tx) => tx.data);
export const getTetherData = (state) =>
  state.balances.usdtData.map((tx) => tx.data);
export const getBinanceData = (state) =>
  state.balances.busdData.map((tx) => tx.data);
export const getUSDCoinData = (state) =>
  state.balances.usdcData.map((tx) => tx.data);
export const getBitcoinData = (state) =>
  state.balances.btcData.map((tx) => tx.data);

// Currently, all data are pulled and search creteria will be done in FE
// If we change to search via API, just remove this and everthing relate
export const filterTransactions = (data, criteria = defaultSearchCriteria) => {
  if (!data || data.length === 0) return [];

  let result = [...data];
  const { page, limit, time, search } = criteria;

  // Time
  if (time) {
    const today = Date.now();
    switch (time) {
      case 'lastWeek': {
        const lastSevenDays = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastSevenDays / 1000);
        break;
      }
      case 'lastMonth': {
        const lastThirtyDays = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastThirtyDays / 1000);
        break;
      }
      default: {
        if (typeof time === 'object' && time.from && time.to) {
          result = result.filter(item =>
            item.created_at >= time.from.getTime() / 1000 &&
            item.created_at <= time.to.getTime() / 1000);
        }
        break;
      }
    }
  }

  // Search
  if (search) {
    result = result.filter(
      (item) =>
        item.status &&
        item.status.toLowerCase().includes(search.toLowerCase()),
    );
  }

  const csvData = result.map(item => {
    const { amount, method, created, currency, status, total, fee } = item;
    if (method === undefined) {
      return {
        'amount before fee': amount,
        amount: total,
        fee: (amount * fee).toFixed(2),
        status,
        date: created,
        currency,
      };
    }
    if (method === 'on-chain' || method === 'lightning') {
      return {
        'amount before fee': parseFloat(amount) / 100000000,
        amount: total,
        fee: item.txFee / 100000000,
        status,
        date: created,
        method,
        coin: 'bitcoin',
      };
    }
    if (method === 'USDt' || method === 'BUSD' || method === 'USDC') {
      const scFee = amount - total;
      return {
        'amount before fee': amount,
        amount: total,
        fee: scFee.toFixed(2),
        status,
        date: created,
        method: 'stablecoin',
        coin: method,
      };
    }

    return [];
  });
  result = result.slice((page - 1) * limit, page * limit);

  return {
    list: result,
    csvData,
  };
};

export const calculateIncomeRate = (transactions) => {
  let rate;
  if (transactions.length !== 0) {
    let todayIncome = 0;
    let yesterdayIncome = 0;
    transactions.forEach((transaction) => {
      const date = transaction.data.createdAt.split(' ');
      const day = parseInt(date[0], 10);
      const month = date[1];
      const year = parseInt(`20${date[2]}`, 10);
      if (dateUtil.isToday(day, month, year)) {
        todayIncome += transaction.data.amount;
      } else if (dateUtil.isYesterday(day, month, year)) {
        yesterdayIncome += transaction.data.amount;
      }
    });
    if (yesterdayIncome === 0) return 0;
    rate = (todayIncome / yesterdayIncome) * 100;
  }
  return rate || 0;
};

export default handleActions(
  new Map([
    [
      setTransactionsData,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
    [
      setIncomeRateData,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
  ]),
  { ...initialStates },
);
