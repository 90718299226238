import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

import { getVerified2FAStatus } from '../../redux/ducks/auth.duck';

/**
 * @function withoutVerifiedTwoFactor
 * @param {*} Component
 * @description Allow user who has not verified Two-factor Authentication yet access component
 */
const withoutVerifiedTwoFactor = (Component) => (props) => {
  const verified2FA = useSelector(getVerified2FAStatus);

  if (!verified2FA) {
    return <Component {...props} />;
  }

  return <Redirect to="/dashboard" />;
};

export default withoutVerifiedTwoFactor;
