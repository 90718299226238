import { takeLatest, all, put, call, select } from 'redux-saga/effects';

import { apiUtil } from '../../utils';
import { setLoading } from '../ducks/loading.duck';
import { notifyError } from '../ducks/notification.duck';
import { getCurrentUserProfile } from '../ducks/auth.duck';
import { callGetRates, setBaseRates, setRates,
  setChangePercentage, setBtcUsdtRate,
  setBtcBusdRate } from '../ducks/bitcoin.duck';

function* onGetRatesFlows() {
  yield takeLatest(callGetRates, function* onGetRates() {
    try {
      yield put(setLoading(true));
      const user = yield select(getCurrentUserProfile);
      if (!user.defaultCurrency) {
        throw new Error('Cannot get user defaultCurrency setting');
      }
      const btcRatesResponse = yield call(
        apiUtil.bitcoin.getRate,
        user.defaultCurrency,
      );
      // Currently API only return rate of today
      yield put(setRates({ today: btcRatesResponse.data }));
      yield put(setChangePercentage(
        btcRatesResponse.change,
      ));
      yield put(setBtcUsdtRate(
        btcRatesResponse.btcUsdt,
      ));
      yield put(setBtcBusdRate(
        btcRatesResponse.btcBusd,
      ));

      const baseRatesResponse = yield call(
        apiUtil.bitcoin.getBaseRate,
        user.defaultCurrency,
      );
      // Currently API only return USD rate of today
      yield put(setBaseRates({ baseRates: baseRatesResponse.data }));
    } catch (error) {
      yield put(
        notifyError({ message: 'BITCOIN.ERROR_MESSAGE.CANNOT_GET_RATES' }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* saga() {
  yield all([onGetRatesFlows()]);
}
