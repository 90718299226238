import React, { Suspense, memo, useEffect, useMemo } from 'react';
import { Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import ROUTES from './constants/ROUTES';
import { generateRoutesUtil } from './utils';
import Loading from './components/Loading/Loading';
import { initializeAuth } from './redux/ducks/auth.duck';
import { getLoading } from './redux/ducks/loading.duck';
import useStyles from './app.styles';

const App = () => {
  useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const routes = useMemo(() => generateRoutesUtil(ROUTES), []);

  useEffect(() => {
    dispatch(initializeAuth());
  }, []);

  return (
    <>
      <Suspense fallback={<Loading open />}>
        <Switch>{routes}</Switch>
        <Loading open={loading} />
      </Suspense>
    </>
  );
};

export default memo(App);
