import { createAction, handleActions } from 'redux-actions';

import BREAKPOINTS from '../../../../constants/BREAKPOINTS';

const PREFIX = 'SIDEBAR';
const initialStates = {
  hidden: window.innerWidth <= BREAKPOINTS.maxMd,
};

export const setSidebarState = createAction(`${PREFIX}/SET_STATE`);

export const getSidebarState = (state) => state.sidebar;

export default handleActions(
  new Map([
    [setSidebarState, (state, { payload }) => ({ ...state, ...payload })],
  ]),
  { ...initialStates },
);
