/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/OVERVIEW';
const initialStates = {
  selectedDenomination: 'BTC',
};

export const setSelectedDenomination = createAction(`${PREFIX}/SET_SELECTED_DENOMINATION`);

export const getSelectedDenomination = (state) => state.overview.selectedDenomination;

export default handleActions(
  new Map([
    [
      setSelectedDenomination,
      (state, { payload }) => ({
        ...state,
        selectedDenomination: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
