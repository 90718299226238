import currency from 'currency.js';
import Big from 'big.js';

const units = {
  BTC: new Big(1),
  mBTC: new Big(0.001),
  μBTC: new Big(0.000001),
  bit: new Big(0.000001),
  Satoshi: new Big(0.00000001),
  sat: new Big(0.00000001),
  sats: new Big(0.00000001),
};

const convert = (value, fromUnit, toUnit) => {
  const fromFactor = units[fromUnit];
  if (fromFactor === undefined) {
    throw new Error(`'${fromUnit}' is not a bitcoin unit`);
  }

  const toFactor = units[toUnit];
  if (toFactor === undefined) {
    throw new Error(`'${toUnit}' is not a bitcoin unit`);
  }

  if (Number.isNaN(value)) {
    throw new Error('value is not a number');
  }

  return new Big(value).times(fromFactor).div(toFactor).toNumber();
};

const abbreviator = (num, dec) => {
  // 2 decimal places => 100, 3 => 1000, etc
  const decPlaces = 10 ** dec;
  let number = num;
  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B', 'T'];

  let i = abbrev.length - 1;
  while (i >= 0) {
    const size = 10 ** ((i + 1) * 3);

    if (size <= number) {
      number = Math.round(number * (decPlaces / size)) / decPlaces;
      if ((number === 1000) && (i < abbrev.length - 1)) {
        number = 1;
        i += 1;
      }
      number += abbrev[i];
      break;
    }
    i -= 1;
  }
  return number;
};

const toCurrency = (btcAmount, exchangeRate) => {
  if (
    !btcAmount ||
    !exchangeRate ||
    Number.isNaN(parseFloat(btcAmount)) ||
    Number.isNaN(parseFloat(exchangeRate))
  ) {
    return 0;
  }

  return Number(
    parseFloat(Number(btcAmount) * Number(exchangeRate)).toFixed(2),
  );
};

const formatBTC = (value) => parseFloat(value || 0).toFixed(8);
const formatMBTC = (value) => parseFloat(value || 0).toFixed(5);
const formatSats = (value) =>
  currency(value, {
    symbol: '',
    separator: ',',
    decimal: '.',
    precision: 0,
  }).format();
const formatUSDt = (value) => parseFloat(value || 0).toFixed(2);
const formatBUSD = (value) => parseFloat(value || 0).toFixed(2);
const formatUSDC = (value) => parseFloat(value || 0).toFixed(2);
const formatCash = (value, unit) => (unit === 'VND' ?
  Math.floor(parseFloat(value || 0)) : parseFloat(value || 0).toFixed(2));

const isCryptoCurrency = (currency) => {
  switch (currency) {
    case 'USDT':
    case 'BUSD':
    case 'USDC':
    case 'BTC':
    case 'MBTC':
    case 'SATS':
      return true;
    default:
      return false;
  }
};

const formatByUnit = (isCurrencyShowed, value, unit, isCompacted) => {
  let val = value;
  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  switch (unit) {
    case 'SATS':
      val = isCompacted && value >= 1000000 ?
        abbreviator(val, 2) : `${formatSats(Math.round(value))}`;
      break;
    default:
      val = `${numberWithCommas(currency(value, {
        symbol: '',
        separator: ',',
        decimal: '.',
        precision: 2,
      }).format())}`;
      break;
  }
  if (isCurrencyShowed) {
    return `${val} ${unit}`;
  }
  return val;
};

export default {
  convert,
  toCurrency,
  formatBTC,
  formatMBTC,
  formatSats,
  formatUSDt,
  formatBUSD,
  formatUSDC,
  formatCash,
  isCryptoCurrency,
  formatByUnit,
};
