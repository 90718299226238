import { lazy } from 'react';
import { compose } from 'redux';

import {
  withAuth,
  withVerifiedEmail,
  // withSetUpTwoFactor,
  withVerifiedTwoFactor,
  withoutUsedSecretKey,
  withoutKYCEnabled,
} from '../HOCs';

// eslint-disable-next-line max-len
const Dashboard = lazy(() => import('../containers/Dashboard'));
const TwoFactor = lazy(() => import('../containers/TwoFactor'));
const Authentication = lazy(() => import('../containers/Authentication'));
const Donation = lazy(() => import('../containers/Donation'));
const Checkout = lazy(() => import('../containers/Checkout'));
const Survey = lazy(() => import('../containers/Survey'));
const WithdrawSuccess = lazy(() =>
  import('../containers/Dashboard/Withdrawals/WithdrawSuccess'));
const PayoutsSuccess = lazy(() =>
  import('../containers/Dashboard/Payouts/PayoutsSuccess'));
// eslint-disable-next-line max-len
const WithdrawalsConfirmCash = lazy(() => import('../containers/Dashboard/Withdrawals/WithdrawalsConfirmCash'));
// eslint-disable-next-line max-len
const WithdrawalsConfirm = lazy(() => import('../containers/Dashboard/Withdrawals/WithdrawalsConfirm'));
// eslint-disable-next-line max-len
const PayoutsConfirm = lazy(() => import('../containers/Dashboard/Payouts/PayoutsConfirm'));
// eslint-disable-next-line max-len
const PaymentsExtension = lazy(() => import('../containers/Dashboard/Invoices/Extensions/PaymentsExtension'));
const VerifyEmail = lazy(() => import('../containers/VerifyEmail'));
// eslint-disable-next-line max-len
const NotificationConfirm = lazy(() => import('../containers/Dashboard/Settings/Notifications/RequestConfirm'));
// eslint-disable-next-line max-len
const NotificationSuccess = lazy(() => import('../containers/Dashboard/Settings/Notifications/RequestSuccess'));
// eslint-disable-next-line max-len
const ConfirmUpdateEmail = lazy(() => import('../containers/Dashboard/Settings/Personal/ConfirmUpdateEmail'));
export default [
  {
    path: '/verify/email',
    title: 'Email Verified',
    component: VerifyEmail,
    exact: true,
  },
  {
    path: '/payments/:code',
    title: 'Payments',
    component: PaymentsExtension,
    exact: true,
  },
  {
    path: '/donation/:code',
    title: 'Redirecting',
    to: '/payments/:code',
    redirect: true,
    exact: true,
  },
  {
    path: '/cashconfirm/:withdrawalCashId/:userId',
    title: 'Withdrawal Confirm',
    component: WithdrawalsConfirmCash,
    exact: true,
  },
  {
    path: '/confirm/:withdrawalCashId/:userId',
    title: 'Withdrawal Confirm',
    component: WithdrawalsConfirm,
    exact: true,
  },
  {
    path: '/confirm-notifications/:notificationId/:userId',
    title: 'Notification Confirm',
    component: NotificationConfirm,
    exact: true,
  },
  {
    path: '/confirm-payouts/:payoutId/:userId',
    title: 'Payouts Confirm',
    component: PayoutsConfirm,
    exact: true,
  },
  {
    path: '/confirm-personal/:requestId/:userId',
    title: 'Email Update Confirm',
    component: ConfirmUpdateEmail,
    exact: true,
  },
  {
    path: '/survey',
    component: compose(withAuth, withVerifiedEmail, withoutKYCEnabled)(Survey),
    title: 'KYC/AML',
    exact: true,
  },
  {
    path: '/checkout/:code',
    component: Checkout,
    title: 'Checkout',
    exact: true,
  },
  {
    path: '/dashboard',
    component: compose(
      withAuth,
      withVerifiedEmail,
      // withSetUpTwoFactor,
      withVerifiedTwoFactor,
      withoutUsedSecretKey,
    )(Dashboard),
    title: 'Dashboard',
    exact: false,
  },
  {
    path: '/two-factor',
    component: compose(
      withAuth,
      withVerifiedEmail,
    )(TwoFactor),
    title: 'Two-factor Authentication',
    exact: false,
  },
  {
    path: '/donation',
    title: 'Donation not found',
    to: '/dashboard',
    redirect: true,
    exact: true,
  },
  {
    path: '/donation/:code',
    component: Donation,
    title: 'Donation',
    exact: true,
  },
  {
    path: '/withdraw-success',
    component: WithdrawSuccess,
    title: 'Withdraw Success',
    exact: true,
  },
  {
    path: '/notification-success',
    component: NotificationSuccess,
    title: 'Email Notification Success',
    exact: true,
  },
  {
    path: '/payouts-success',
    component: PayoutsSuccess,
    title: 'Payout Success',
    exact: true,
  },
  {
    path: '/',
    component: Authentication,
    title: 'Authentication',
    exact: false,
  },
];
