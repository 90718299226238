import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import moment from 'moment';

import { notifyError } from '../../../../redux/ducks/notification.duck';
import { getAuth } from '../../../../redux/ducks/auth.duck';
import { rsf, firestore } from '../../../../services/firebase';

import {
  callGetHistoryTransactions,
  getHistorySearchCriteria,
  setHistoryTransactionsData,
  filterTransactions,
  callSyncHistoryTransactions,
} from './historyTransactions.duck';

function* onGetHistoryListTransactionsFlow() {
  yield takeLatest(callGetHistoryTransactions,
    function* onGetHistoryListTransactions({
      payload,
    }) {
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }
      const { uid } = auth;
      const { tab } = payload;
      let selectedCoins = 'btc';
      switch (tab) {
        case 0:
          selectedCoins = 'cash';
          break;
        case 1:
          selectedCoins = 'btc';
          break;
        case 2:
          selectedCoins = 'usdt';
          break;
        default:
          selectedCoins = 'busd';
      }
      const searchCriteria = yield select(getHistorySearchCriteria);
      const newSearchCriteria = { ...searchCriteria, ...payload };
      try {
        yield put(setHistoryTransactionsData({ isFetching: true }));
        const coinCreditCollection = yield call(rsf.firestore.getCollection,
          firestore.collection('coinCredit')
            .where('uid', '==', uid)
            .where('coinType', '==', selectedCoins));
        const coinDebitCollection = yield call(rsf.firestore.getCollection,
          firestore.collection('coinDebit')
            .where('uid', '==', uid)
            .where('coinType', '==', selectedCoins));
        let instantCashQuery = null;
        if (selectedCoins === 'cash') {
          instantCashQuery = firestore.collection('instantCash')
            .where('uid', '==', uid);
        } else {
          instantCashQuery = firestore.collection('instantCash')
            .where('uid', '==', uid)
            .where('coinType', '==', selectedCoins);
        }
        const instantCashCollection = yield call(rsf.firestore.getCollection,
          instantCashQuery);
        const convertData = [...coinCreditCollection.docs
          .map((doc) => doc.data())
          .map((doc) => ({
            created: moment(
              doc.createdAt * 1000,
            ).format('MMM D, YYYY h:mm a'),
            created_at: doc.createdAt,
            from: doc.coinTypeConvertedFrom.toUpperCase(),
            to: doc.coinType.toUpperCase(),
            total: selectedCoins === 'btc' ?
              parseFloat(doc.amount / 100000000).toFixed(8) : doc.amount,
          })),
        ...coinDebitCollection.docs
          .map((doc) => doc.data())
          .map((doc) => ({
            created: moment(
              doc.createdAt * 1000,
            ).format('MMM D, YYYY h:mm a'),
            created_at: doc.createdAt,
            from: doc.coinType.toUpperCase(),
            to: doc.coinTypeConvertedTo.toUpperCase(),
            total: selectedCoins === 'btc' ?
              parseFloat(doc.amount / 100000000).toFixed(8) : doc.amount,
          })),
        ...instantCashCollection.docs
          .map((item) => {
            const obj = item.data();
            obj.id = item.id;
            // eslint-disable-next-line max-len
            obj.from = obj.coinType ? obj.coinType.toUpperCase() : selectedCoins;
            obj.to = obj.fiat;
            // eslint-disable-next-line max-len
            obj.created = moment(obj.created_at * 1000).format('MMM D, YYYY h:mm a');
            obj.total = parseFloat(obj.coinAmount).toFixed(2);
            if ((obj.coinType === 'btc') && tab === 1) {
              obj.total = (Math.ceil(obj.coinAmount) / 100000000).toFixed(8);
            } else if (tab === 0) {
              obj.total = obj.instantCash.toFixed(2);
            }
            return obj;
          })]
          .sort((a, b) => b.created_at - a.created_at);
        yield put(
          setHistoryTransactionsData({
            isFetching: false,
            tab,
            searchCriteria,
            allData: convertData,
            totalRecords: convertData.length,
            ...filterTransactions(convertData, newSearchCriteria),
          }),
        );
      } catch (e) {
        yield put(
          setHistoryTransactionsData({
            list: [],
            tab,
            totalRecords: 0,
            isFetching: false,
            searchCriteria: newSearchCriteria,
          }),
        );
        yield put(
          notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
        );
      }
    });
}

function* onSyncHistoryTransactionsFlow() {
  yield takeLatest(callSyncHistoryTransactions,
    function* onSyncHistoryTransactions({
      payload,
    }) {
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }
      const { uid } = auth;
      const { tab } = payload;
      let selectedCoins = 'btc';
      switch (tab) {
        case 1:
          selectedCoins = 'btc';
          break;
        case 2:
          selectedCoins = 'usdt';
          break;
        default:
          selectedCoins = 'busd';
      }
      const searchCriteria = yield select(getHistorySearchCriteria);
      const newSearchCriteria = { ...searchCriteria, ...payload };
      try {
        yield put(setHistoryTransactionsData({ isFetching: true }));
        const coinCreditCollection = yield call(rsf.firestore.getCollection,
          firestore.collection('coinCredit')
            .where('uid', '==', uid)
            .where('coinType', '==', selectedCoins));
        const coinDebitCollection = yield call(rsf.firestore.getCollection,
          firestore.collection('coinDebit')
            .where('uid', '==', uid)
            .where('coinType', '==', selectedCoins));
        const instantCashCollection = yield call(rsf.firestore.getCollection,
          firestore.collection('instantCash')
            .where('uid', '==', uid)
            .where('coinType', '==', selectedCoins));
        const convertData = [...coinCreditCollection.docs
          .map((doc) => doc.data())
          .map((doc) => ({
            created: moment(
              doc.createdAt * 1000,
            ).format('MMM D, YYYY h:mm a'),
            created_at: doc.createdAt,
            from: doc.coinTypeConvertedFrom,
            to: doc.coinType,
            total: doc.amount,
          })),
        ...coinDebitCollection.docs
          .map((doc) => doc.data())
          .map((doc) => ({
            created: moment(
              doc.createdAt * 1000,
            ).format('MMM D, YYYY h:mm a'),
            created_at: doc.createdAt,
            from: doc.coinType,
            to: doc.coinTypeConvertedTo,
            total: doc.amount,
          })),
        ...instantCashCollection.docs
          .map((doc) => doc.data())
          .map((item) => {
            const obj = item.data();
            obj.id = item.id;
            obj.from = obj.coinType ? obj.coinType.toUpperCase() : obj.coinType;
            obj.to = obj.fiat;
            // eslint-disable-next-line max-len
            obj.created = moment(obj.created_at * 1000).format('MMM D, YYYY h:mm a');
            obj.total = parseFloat(obj.coinAmount).toFixed(2);
            if ((obj.coinType === 'btc')
            && tab === 1) {
              obj.total = (Math.ceil(obj.coinAmount) / 100000000).toFixed(8);
            }
            return obj;
          })]
          .sort((a, b) => b.created_at - a.created_at);
        yield put(
          setHistoryTransactionsData({
            isFetching: false,
            tab,
            searchCriteria,
            allData: convertData,
            totalRecords: convertData.length,
            ...filterTransactions(convertData, newSearchCriteria),
          }),
        );
      } catch (e) {
        yield put(
          setHistoryTransactionsData({
            list: [],
            tab,
            totalRecords: 0,
            isFetching: false,
            searchCriteria: newSearchCriteria,
          }),
        );
        yield put(
          notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
        );
      }
    });
}

export default function* saga() {
  yield all([
    onGetHistoryListTransactionsFlow(),
    onSyncHistoryTransactionsFlow(),
  ]);
}
