import { takeLatest, all, put, select, call } from 'redux-saga/effects';
import moment from 'moment';

import { notifyError } from '../../../../redux/ducks/notification.duck';
import { getAuth } from '../../../../redux/ducks/auth.duck';
import { rsf, firestore } from '../../../../services/firebase';

import {
  callGetListTransactions,
  getSearchCriteria,
  setTransactionsData,
  filterTransactions,
  getAllTransactions,
  getTab,
  callSyncTransactions,
  callGetFeeData,
} from './listPayouts.duck';

function* onGetFeeDataFlow() {
  yield takeLatest(callGetFeeData, function* onGetFeeData() {
    const auth = yield select(getAuth);
    if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
      throw new Error('Cannot get Authentication');
    }
    const ref = firestore.collection('feeSettings');
    const snapshot = yield call(rsf.firestore.getCollection, ref);
    let feeSettings = [];
    snapshot.forEach((doc) => {
      feeSettings.push(doc.data());
    });
    feeSettings = feeSettings.sort((a, b) => b.created_at - a.created_at);
    const bitcoinFees = feeSettings.filter((el) =>
      el.coinType === 'bitcoin');
    const stableCoinFees = feeSettings.filter((el) =>
      el.coinType === 'stable-coins');
    const fee = {
      bitcoin: bitcoinFees.length > 0 ? bitcoinFees[0].value : 15,
      stableCoin: stableCoinFees.length > 0 ? stableCoinFees[0].value : 15,
    };
    yield put(
      setTransactionsData({
        fee,
      }),
    );
  });
}

function* onGetListTransactionsFlow() {
  yield takeLatest(callGetListTransactions, function* onGetListTransactions({
    payload,
  }) {
    const auth = yield select(getAuth);
    if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
      throw new Error('Cannot get Authentication');
    }

    const { uid } = auth;
    const { tab } = payload;
    const collection = 'payoutsRequested';
    const ref = firestore.collection(collection);
    const snapshot = yield call(rsf.firestore.getCollection,
      ref.where('uid', '==', uid));

    let withdrawals = [];
    snapshot.forEach(item => {
      const obj = item.data();
      obj.id = item.id;
      obj.created = moment(obj.created_at * 1000).format('D MMMM, YYYY h:mm A');
      const unix = moment().unix();
      obj.status = 'Pending';
      if (obj.created_at + 1800 < unix && !obj.confirmations.user) {
        obj.status = 'Expired';
      } else if (obj.adminDeclined) {
        obj.status = 'Declined';
      } else if (obj.adminConfirmedDateTime !== undefined) {
        obj.status = 'Complete';
      }
      if (obj.coinType === 'btc'
        && tab === 0) {
        obj.totalAmount = parseFloat(obj.totalAmount).toFixed(8);
        withdrawals.push(obj);
      } else if ((obj.coinType === 'usdt' || obj.coinType === 'usdc')
        && tab === 1) {
        obj.totalAmount = parseFloat(obj.totalAmount).toFixed(2);
        withdrawals.push(obj);
      }
    });

    withdrawals = withdrawals.sort((a, b) =>
      b.created_at - a.created_at);

    yield put(setTransactionsData({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    try {
      // TODO integration: call get list transactions base on cash/coin
      const currentData = yield select(getAllTransactions);
      const currentTab = yield select(getTab);
      if (currentData && currentData.length > 0 &&
        (tab === undefined || tab === currentTab)) {
        yield put(
          setTransactionsData({
            isFetching: false,
            searchCriteria: newSearchCriteria,
            ...filterTransactions(currentData, newSearchCriteria),
          }),
        );
      } else {
        yield put(
          setTransactionsData({
            isFetching: false,
            tab,
            searchCriteria: newSearchCriteria,
            allData: withdrawals,
            ...filterTransactions(withdrawals, newSearchCriteria),
          }),
        );
      }
    } catch (error) {
      yield put(
        setTransactionsData({
          list: [],
          tab,
          totalRecords: 0,
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
      );
    }
  });
}

function* onSyncTransactionsFlow() {
  yield takeLatest(callSyncTransactions, function* onSyncTransactions({
    payload,
  }) {
    const auth = yield select(getAuth);
    if (!auth || !auth.stsTokenManager ||
      !auth.stsTokenManager.accessToken) {
      throw new Error('Cannot get Authentication');
    }

    const { uid } = auth;
    const { tab } = payload;
    const collection = 'payoutsRequested';
    const ref = firestore.collection(collection);
    const snapshot = yield call(rsf.firestore.getCollection,
      ref.where('uid', '==', uid));

    let withdrawals = [];
    snapshot.forEach(item => {
      const obj = item.data();
      obj.id = item.id;
      obj.created = moment(obj.created_at * 1000).format('D MMMM, YYYY h:mm A');
      const unix = moment().unix();
      obj.status = 'Pending';
      if (obj.created_at + 1800 < unix && !obj.confirmations.user) {
        obj.status = 'Expired';
      } else if (obj.adminDeclined) {
        obj.status = 'Declined';
      } else if (obj.adminConfirmedDateTime !== undefined) {
        obj.status = 'Complete';
      }
      if (obj.coinType === 'btc'
        && tab === 0) {
        obj.totalAmount = parseFloat(obj.totalAmount).toFixed(8);
        withdrawals.push(obj);
      } else if ((obj.coinType === 'usdt' || obj.coinType === 'usdc')
        && tab === 1) {
        obj.totalAmount = parseFloat(obj.totalAmount).toFixed(2);
        withdrawals.push(obj);
      }
    });

    withdrawals = withdrawals.sort((a, b) =>
      b.created_at - a.created_at);

    yield put(setTransactionsData({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    try {
      yield put(
        setTransactionsData({
          isFetching: false,
          tab,
          searchCriteria: newSearchCriteria,
          allData: withdrawals,
          ...filterTransactions(withdrawals, newSearchCriteria),
        }),
      );
    } catch (error) {
      yield put(
        setTransactionsData({
          list: [],
          tab,
          totalRecords: 0,
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
      );
    }
  });
}

export default function* saga() {
  yield all([
    onGetListTransactionsFlow(),
    onSyncTransactionsFlow(),
    onGetFeeDataFlow(),
  ]);
}
