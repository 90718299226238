import Cookie from 'js-cookie';

import { APP_NAME_PREFIX, TOKEN_2FA_EXPIRED_IN } from '../configs';

// Cookie 2FA verification status
const VERIFIED_2FA = `${APP_NAME_PREFIX}/VERIFIED_2FA`;

const setVerified2FAStatus = (status) => {
  Cookie.set(VERIFIED_2FA, status, { expires: TOKEN_2FA_EXPIRED_IN });
};
const getVerified2FAStatus = () => Cookie.get(VERIFIED_2FA);
const removeVerified2FAStatus = () => Cookie.remove(VERIFIED_2FA);

export default {
  setVerified2FAStatus,
  getVerified2FAStatus,
  removeVerified2FAStatus,
};
