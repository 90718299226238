/* eslint-disable max-len */
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';

import tabs from './PayoutsTabs/payoutsTabs.duck';
import subTabs from './PayoutSubTabs/payoutSubTabs.duck';
import transactions from './ListPayouts/listPayouts.duck';

const PREFIX = 'DASHBOARD/PAYOUTS';

export const callPayoutsVerifyOTP = createAction(`${PREFIX}/VERIFY_OTP`);
export const callPayoutsCreatePAYOUTS = createAction(`${PREFIX}/CREATE_PAYOUTS`);

export default combineReducers({
  tabs,
  subTabs,
  transactions,
});
