import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import moment from 'moment';

import { setLoading } from '../../../../redux/ducks/loading.duck';
import {
  notifyError,
  notifySuccess,
} from '../../../../redux/ducks/notification.duck';
import { rsf, firestore } from '../../../../services/firebase';

import {
  callGetListItems,
  callCreateItem,
  callUpdateItem,
  callDeleteItem,
  callCreateCategory,
  callUpdateCategory,
  callDeleteCategory,
  getSearchCriteria,
  setListItems,
} from './manageItems.duck';
import { getAuth } from '../../../../redux/ducks/auth.duck';

function* onGetListItemsFlow() {
  yield takeLatest(callGetListItems, function* onGetListItems(
    { payload },
  ) {
    yield put(setListItems({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    try {
      // TODO integration: call get list
      const auth = yield select(getAuth);
      if (!auth || !auth.uid) {
        throw new Error('Cannot get Authentication');
      }
      const { uid } = auth;
      const { search, page, limit } = newSearchCriteria;

      const catRef = firestore.collection('saleItemCategories');
      const catSnapshot = yield call(rsf.firestore.getCollection,
        catRef.where('uid', '==', uid));

      const categories = [];
      catSnapshot.forEach(item => {
        const data = item.data();
        const { name, uid, createdAt } = data;
        const category = {
          value: item.id, label: name, uid, createdAt,
        };
        categories.push(category);
      });

      const colRef = firestore.collection('saleItems');
      const snapshot = yield call(rsf.firestore.getCollection,
        colRef.where('uid', '==', uid));

      let items = [];
      snapshot.forEach(item => {
        const data = item.data();
        data.id = item.id;

        const category = categories.filter(
          category => data.categoryId === category.value,
        );

        data.category = category.length > 0 ? category[0].label : '';
        data.createdAt = data.createdAt ?
          new Date(data.createdAt * 1000)
          : new Date();
        items.push(data);
      });

      if (newSearchCriteria.category !== 'all') {
        items = items.filter((item) => (
          item.categoryId === newSearchCriteria.category
        ));
      }

      if (search !== '') {
        items = items.filter(item => item.name.indexOf(search) >= 0);
      }

      items = items.sort((a, b) => a.createdAt > b.createdAt);
      const totalRecords = items.length;
      items = items.slice((page - 1) * limit, page * limit);

      yield put(
        setListItems({
          list: items,
          totalRecords,
          isFetching: false,
          searchCriteria: newSearchCriteria,
          categories,
        }),
      );
    } catch (error) {
      yield put(
        setListItems({
          list: [],
          totalRecords: 0,
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.ERROR_MESSAGE.CANNOT_GET_LIST',
        }),
      );
    }
  });
}

function* onCreateItemFlow() {
  yield takeLatest(callCreateItem, function* onCreateItem({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.uid) {
        throw new Error('Cannot get Authentication');
      }
      const { uid } = auth;
      const { name, price, category, currency } = data;
      // TODO integration: call create item then update list items
      yield call(
        rsf.firestore.addDocument,
        'saleItems',
        {
          name,
          price: parseFloat(price),
          uid,
          categoryId: category.value,
          currency: currency.value,
          createdAt: moment().unix('X'),
          updatedAt: moment().unix('X'),
        },
      );
      yield put(
        notifySuccess({
          message: 'INVOICES.MANAGE_ITEMS.SUCCESS_MESSAGE.CREATE_ITEM',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.ERROR_MESSAGE.CANNOT_CREATE_ITEM',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onUpdateItemFlow() {
  yield takeLatest(callUpdateItem, function* onUpdateItem({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const { id, category, categoryId, name, price, currency } = data;

      const snapshot = yield call(rsf.firestore.getDocument, `saleItems/${id}`);
      const item = snapshot.data();
      const { uid, createdAt } = item;

      yield call(
        rsf.firestore.setDocument,
        `saleItems/${id}`,
        {
          uid,
          categoryId: category.value ? category.value : categoryId,
          currency: currency.value,
          name,
          price: parseFloat(price),
          createdAt,
          updatedAt: moment().unix('X'),
        },
      );

      // eslint-disable-next-line no-unreachable
      yield put(
        notifySuccess({
          message: 'INVOICES.MANAGE_ITEMS.SUCCESS_MESSAGE.UPDATE_ITEM',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.ERROR_MESSAGE.CANNOT_UPDATE_ITEM',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onDeleteItemFlow() {
  yield takeLatest(callDeleteItem, function* onDeleteItem({
    payload: { id, callback },
  }) {
    try {
      yield put(setLoading(true));
      yield call(rsf.firestore.deleteDocument, `saleItems/${id}`);
      yield put(
        notifySuccess({
          message: 'INVOICES.MANAGE_ITEMS.SUCCESS_MESSAGE.DELETE_ITEM',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.ERROR_MESSAGE.CANNOT_DELETE_ITEM',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onCreateCategoryFlow() {
  yield takeLatest(callCreateCategory, function* onCreateCategory({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.uid) {
        throw new Error('Cannot get Authentication');
      }
      const { uid } = auth;
      const { name } = data;
      // TODO integration: call create item then update list items
      yield call(
        rsf.firestore.addDocument,
        'saleItemCategories',
        {
          name,
          uid,
          createdAt: moment().unix('X'),
          updatedAt: moment().unix('X'),
        },
      );
      yield put(
        notifySuccess({
          message: 'INVOICES.MANAGE_ITEMS.CATEGORY.SUCCESS_MESSAGE.CREATE',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.CATEGORY.ERROR_MESSAGE.CANNOT_CREATE',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onUpdateCategoryFlow() {
  yield takeLatest(callUpdateCategory, function* onUpdateCategory({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      yield all(data.map(category => {
        const { value, label, uid, createdAt } = category;
        if (value === undefined) {
          return false;
        }

        return call(rsf.firestore.setDocument,
          `saleItemCategories/${value}`,
          {
            uid,
            name: label,
            createdAt,
            updatedAt: moment().unix('X'),
          });
      }));

      // eslint-disable-next-line no-unreachable
      yield put(
        notifySuccess({
          message: 'INVOICES.MANAGE_ITEMS.SUCCESS_MESSAGE.UPDATE_ITEM',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.ERROR_MESSAGE.CANNOT_UPDATE_ITEM',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onDeleteCategoryFlow() {
  yield takeLatest(callDeleteCategory, function* onDeleteCategory({
    payload: { id, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.uid) {
        throw new Error('Cannot get Authentication');
      }
      const { uid } = auth;

      const colRef = firestore.collection('saleItems');
      const snapshot = yield call(rsf.firestore.getCollection,
        colRef.where('uid', '==', uid));
        // colRef.where('categoryId', '==', id));

      const items = [];
      snapshot.forEach(item => {
        const data = item.data();
        if (data.categoryId === id) {
          items.push({ id: item.id });
        }
      });

      // Remove category items from collection.
      yield all(items.map(item =>
        call(rsf.firestore.deleteDocument, `saleItems/${item.id}`)));

      // Remove Category from collection
      yield call(rsf.firestore.deleteDocument, `saleItemCategories/${id}`);

      // eslint-disable-next-line no-unreachable
      yield put(
        notifySuccess({
          message: 'INVOICES.MANAGE_ITEMS.CATEGORY.SUCCESS_MESSAGE.DELETE',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICES.MANAGE_ITEMS.CATEGORY.ERROR_MESSAGE.CANNOT_DELETE',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* saga() {
  yield all([
    onGetListItemsFlow(),
    onCreateItemFlow(),
    onUpdateItemFlow(),
    onDeleteItemFlow(),
    onCreateCategoryFlow(),
    onUpdateCategoryFlow(),
    onDeleteCategoryFlow(),
  ]);
}
