import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/PAYOUTS/TABS';
const initialStates = {
  selectedTab: {
    index: 0,
    data: {},
  },
};

export const setSelectedTab = createAction(`${PREFIX}/SET_SELECTED_TAB`);

export const getSelectedTab = (state) => state.payouts.tabs.selectedTab;

export default handleActions(
  new Map([
    [
      setSelectedTab,
      (state, { payload }) => ({
        ...state,
        selectedTab: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
