import { takeLatest, fork, all, put, call, select } from 'redux-saga/effects';

// import axios from 'axios';
import { setLoading } from '../../../redux/ducks/loading.duck';
import { notifyError } from '../../../redux/ducks/notification.duck';
import { getAuth } from '../../../redux/ducks/auth.duck';

import { apiUtil } from '../../../utils';
import transactions from './ListTransactions/listTransactions.saga';
import convertHistory from './HistoryTransactions/historyTransactions.saga';
import {
  callWithdrawVerifyOTP,
  callWithdrawCash,
  callWithdrawBTC,
  callWithdrawStableCoin,
  callWithdrawLnurl,
} from './withdrawals.duck';
import {
  setLnurlModal,
  setWithdrawalLnurl,
} from './WithdrawBTC/withdrawLNURL.duck';

function* onSendRequestWithdrawCashFlow() {
  yield takeLatest(callWithdrawCash, function* onSendRequestWithdrawCash({
    payload: { amount, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }
      const response = yield call(apiUtil.withdrawal.cash, {
        cashWithdrawalAmount: amount,
      });

      if (!response.success) {
        throw new Error(response.message);
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({ message: error.message }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onWithdrawVerifyOTPFlow() {
  yield takeLatest(
    callWithdrawVerifyOTP,
    function* onWithdrawVerifyOTP({ payload: { otp, callback } }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (
          !auth ||
          !auth.uid ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        const response = yield call(apiUtil.authentication.verify2FAOTP, {
          token: auth.stsTokenManager.accessToken,
          otp,
        });
        if (!response.success) {
          throw new Error(response.message);
        }
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message: 'WITHDRAWALS.ERROR_MESSAGE.VERIFY_OTP_FAILED',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    },
  );
}

function* onSendRequestWithdrawBTCFlow() {
  yield takeLatest(callWithdrawBTC, function*
  onSendRequestWithdrawBTC({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }

      const { amount, address, wallet, otp, method } = data;
      if (method === 'lightning') {
        // eslint-disable-next-line no-unreachable
        const response = yield call(apiUtil.withdrawal.lightning, {
          paymentReq: address,
          geoInfo: {},
          currency: 'CAD',
        });

        if (!response.success) {
          throw new Error(response.message);
        }
      } else {
        const response = yield call(apiUtil.withdrawal.onChain, {
          address: wallet,
          amount,
          twoFA: otp,
          geoInfo: {},
          currency: 'CAD',
        });

        if (!response.success) {
          throw new Error(response.message);
        }
      }

      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({ message: error.message }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onSendRequestWithdrawStableCoinFlow() {
  yield takeLatest(callWithdrawStableCoin, function*
  onSendRequestWithdrawStableCoin({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }

      const { address, amount, otp, method } = data;
      const response = yield call(apiUtil.withdrawal.stablecoin, {
        // token: auth.stsTokenManager.accessToken,
        address,
        amount,
        twoFA: otp,
        geoInfo: {},
        method,
      });
      if (!response.success) {
        throw new Error(response.message);
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({ message: error.message === 'CASH REQUEST LIMIT EXCEEDED' ?
          'WITHDRAWALS.ERROR_MESSAGE.NO_BALANCE' : error.message }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onSendRequestWithdrawLnurlFlow() {
  yield takeLatest(callWithdrawLnurl, function*
  onSendRequestWithdrawLnurl({
    payload: { callback, data },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);

      Object.assign(data, {
        minWithdrawable: data.amount,
        maxWithdrawable: data.amount,
        uid: auth.uid,
        tag: 'withdrawRequest',
      });

      const response = yield call(apiUtil.withdrawal.lnurl, { ...data });

      if (response.status === 'OK') {
        yield put(setWithdrawalLnurl(response.data));
      } else {
        throw new Error(response.reason);
      }

      callback(true);

      yield put(setLnurlModal({
        open: true,
      }));
    } catch (error) {
      callback(false);
      yield put(
        notifyError({ message: error.message }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* sagas() {
  yield fork(transactions);
  yield fork(convertHistory);
  yield fork(function* withdrawals() {
    yield all([
      onWithdrawVerifyOTPFlow(),
      onSendRequestWithdrawCashFlow(),
      onSendRequestWithdrawBTCFlow(),
      onSendRequestWithdrawStableCoinFlow(),
      onSendRequestWithdrawLnurlFlow(),
    ]);
  });
}
