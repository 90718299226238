import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import {
  getAuth,
  getCurrentUserProfile,
  getVerified2FAStatus,
} from '../../redux/ducks/auth.duck';

/**
 * @function withoutVerifiedEmail
 * @param {*} Component
 * @description Allow user who already loggedin but has not verified email access component
 */
const withoutVerifiedEmail = (Component) => (props) => {
  const auth = useSelector(getAuth, shallowEqual);
  const user = useSelector(getCurrentUserProfile, shallowEqual);
  const verified2FA = useSelector(getVerified2FAStatus);
  const [disabled2FALogin] = useState(true);

  if (isLoaded(auth)) {
    if (!isEmpty(auth)) {
      if (!auth.emailVerified) {
        return <Component {...props} />;
      }

      if (isLoaded(user)) {
        if (!disabled2FALogin && !user.loginSecretSaved) {
          return <Redirect to="/two-factor/setup" />;
        }

        if (user.reEnableTwoFA) {
          return <Redirect to="/two-factor/secret-key" />;
        }

        if (user.twoFactorSetup && !verified2FA) {
          return <Redirect to="/two-factor/verify-otp" />;
        }

        return <Redirect to="/dashboard" />;
      }

      return <Loading open />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <Loading open />;
};

export default withoutVerifiedEmail;
