import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import { getAuth } from '../../redux/ducks/auth.duck';

/**
 * @function withVerifiedEmail
 * @param {*} Component
 * @description Allow user who already loggedin and verified email access component
 */
const withVerifiedEmail = (Component) => (props) => {
  const auth = useSelector(getAuth, shallowEqual);

  if (isLoaded(auth)) {
    if (!isEmpty(auth)) {
      if (auth.emailVerified) {
        return <Component {...props} />;
      }

      return <Redirect to="/resend-email-verification" />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <Loading open />;
};

export default withVerifiedEmail;
