/* eslint-disable max-len */
import auth from './auth.duck';
import user from './user.duck';
import cart from './cart.duck';
import bitcoin from './bitcoin.duck';
import loading from './loading.duck';
import payments from './payments.duck';
import notification from './notification.duck';
import balances from './balances.duck';

import donation from '../../containers/Donation/donation.duck';
import sidebar from '../../containers/Dashboard/components/Sidebar/sidebar.duck';
import overview from '../../containers/Dashboard/Overview/overview.duck';
import invoices from '../../containers/Dashboard/Invoices/invoices.duck';
import withdrawals from '../../containers/Dashboard/Withdrawals/withdrawals.duck';
import payouts from '../../containers/Dashboard/Payouts/payouts.duck';
import checkout from '../../containers/Checkout/checkout.duck';

export default {
  auth,
  user,
  cart,
  bitcoin,
  loading,
  payments,
  notification,
  donation,
  sidebar,
  overview,
  invoices,
  withdrawals,
  checkout,
  balances,
  payouts,
};
