import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import { getCurrentUserProfile } from '../../redux/ducks/auth.duck';

/**
 * @function withoutUsedSecretKey
 * @param {*} Component
 * @description Allow user who has not used Two-factor Secret Key yet access component
 */
const withoutUsedSecretKey = (Component) => (props) => {
  const user = useSelector(getCurrentUserProfile, shallowEqual);

  if (isLoaded(user)) {
    if (!isEmpty(user)) {
      if (!user.reEnableTwoFA) {
        return <Component {...props} />;
      }

      return <Redirect to="/two-factor/secret-key" />;
    }

    return <Redirect to="/sign-in" />;
  }

  return <Loading open />;
};

export default withoutUsedSecretKey;
