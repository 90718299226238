export const CASH_KYC_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  DECLINED: 'declined',
};

export const PAYMENT_STATUS = {
  ALL: 'all',
  EXPIRED: 'expired',
  PENDING: 'pending',
  COMPLETED: 'complete',
};

export default {
  CASH_KYC_STATUS,
  PAYMENT_STATUS,
};
