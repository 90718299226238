import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'CHECKOUT';
const initialStates = {
  rates: {},
  fee: 0,
  details: {},
  items: [],
  info: {},
};

export const callGetDetails = createAction(`${PREFIX}/GET_DETAILS`);
export const setDetails = createAction(`${PREFIX}/SET_DETAILS`);
export const callGetInfo = createAction(`${PREFIX}/GET_INFO`);
export const setInfo = createAction(`${PREFIX}/SET_INFO`);

export const getRates = (state) => state.checkout.rates;
export const getDetails = (state) => state.checkout.details;
export const getInfo = (state) => state.checkout.info;

export default handleActions(
  new Map([
    [
      setInfo,
      (state, { payload }) => ({ ...state, ...payload }),
    ],
    [
      setDetails,
      (state, { payload }) => ({ ...state, ...payload }),
    ],
  ]),
  { ...initialStates },
);
