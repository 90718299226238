import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'BITCOIN';
const initialStates = {
  rates: {
    today: 0,
    lastDay: 0,
    lastWeek: 0,
    lastMonth: 0,
  },
  baseRates: {
    baseRates: 0,
  },
  change: 0,
};

export const callGetRates = createAction(`${PREFIX}/GET_RATES`);

export const setRates = createAction(`${PREFIX}/SET_RATES`);
export const setBaseRates = createAction(`${PREFIX}/SET_BASE_RATES`);
// eslint-disable-next-line max-len
export const setChangePercentage = createAction(`${PREFIX}/SET_CHANGE_PERCENTAGE`);
export const setBtcUsdtRate = createAction(`${PREFIX}/SET_BTC_USDT_RATE`);
export const setBtcBusdRate = createAction(`${PREFIX}/SET_BTC_BUSD_RATE`);

export const getRates = (state) => state.bitcoin.rates;
export const getBaseRates = (state) => state.bitcoin.baseRates;
export const getChangePercentage = (state) => state.bitcoin.change;
export const getBtcUsdtRate = (state) => state.bitcoin.btcUsdt;
export const getBtcBusdRate = (state) => state.bitcoin.btcBusd;

export default handleActions(
  new Map([
    [
      setRates,
      (state, { payload }) => ({
        ...state,
        rates: { ...state.rates, ...payload },
      }),
    ],
    [
      setBaseRates,
      (state, { payload }) => ({
        ...state,
        baseRates: { ...state.baseRates, ...payload },
      }),
    ],
    [
      setChangePercentage,
      (state, { payload }) => ({
        ...state,
        change: payload,
      }),
    ],
    [
      setBtcUsdtRate,
      (state, { payload }) => ({
        ...state,
        btcUsdt: payload,
      }),
    ],
    [
      setBtcBusdRate,
      (state, { payload }) => ({
        ...state,
        btcBusd: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
