import FirebaseApp from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import ReduxSagaFirebase from 'redux-saga-firebase';

/*
import { FIREBASE_CONFIGS } from '../configs';
*/

const config = {
  apiKey: process.env.FIREBASE_CONFIGS_APIKEY,
  authDomain: process.env.FIREBASE_CONFIGS_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_CONFIGS_DATABASE_URL,
  projectId: process.env.FIREBASE_CONFIGS_PROJECT_ID,
  storageBucket: process.env.FIREBASE_CONFIGS_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_CONFIGS_MSG_SENDER_ID,
  appId: process.env.FIREBASE_CONFIGS_APP_ID,
};

const Firebase = FirebaseApp;
export const firebase = Firebase.initializeApp(config);
export const firebaseAuth = Firebase.auth;
export const serverTimestamp = Firebase.firestore.FieldValue.serverTimestamp();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
firebase.firestore().enablePersistence({ synchronizeTabs: true });
firebase.performance();
export const rsf = new ReduxSagaFirebase(firebase);

export default Firebase;
