import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { apiUtil, bitcoinUtil } from '../../utils';
import { rsf, firestore } from '../../services/firebase';
import { getAuth } from '../ducks/auth.duck';
import { setLoading } from '../ducks/loading.duck';
import { setPaymentsData, filterPayments } from '../ducks/payments.duck';
import { notifyError, notifySuccess } from '../ducks/notification.duck';
import {
  callGetWalletBalance,
  callConvertBTCToCash,
  callConvertStablecoinToCash,
  callConvertBTCToStablecoin,
  callUpdateInstantCash,
  callGetListTransactions,
  callGetListAPIKeys,
  callGenerateAPIKey,
  callRevokeAPIKey,
  setListAPIKeys,
  setWalletBalance,
  setListTransactions,
  callUpdateInstantPercentage,
  callGetWebhookSetting,
  setWebhookSetting,
  callAddWebhookSetting,
  callDeleteWebhookSetting,
  callUpdateWebhookSetting,
} from '../ducks/user.duck';

function* onGetWalletBalanceFlow() {
  yield takeLatest(callGetWalletBalance, function* onGetWalletBalance() {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }
      const walletBalance = yield call(apiUtil.user.getWalletBalance);
      yield put(setWalletBalance({ ...walletBalance }));
    } catch (error) {
      yield put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_GET_WALLET_BALANCE',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function getPeriodData(start, end, method, data) {
  if (method === 'BTC') {
    const btc = data.reduce((total, item) => {
      const { paidVia, status, priceLessPlatformFee } = item;
      if (item.created_at >= start && item.created_at <= end &&
        (status === 'paid' ||
          status === 'expired-paid' ||
          status === 'partially-paid' ||
          status === 'expired-partially-paid') &&
        (paidVia === 'on-chain' || paidVia === 'lightning')) {
        const paidPrice = priceLessPlatformFee;
        return total + parseFloat(paidPrice);
      }
      return total;
    }, 0);

    return btc;
  }
  // todo: rip out stablecoins stuff
  const sc = data.reduce((total, item) => {
    const { paidVia, status, priceLessPlatformFeeSc, paidAmount } = item;
    if (item.created_at >= start && item.created_at <= end &&
      (status === 'paid' ||
        status === 'expired-paid' ||
        status === 'partially-paid') && paidVia === 'stable-coin' &&
      item.method === method && priceLessPlatformFeeSc) {
      // const price = parseFloat(priceLessPlatformFeeSc).toFixed(2);
      const paidAmountValue = parseFloat(parseFloat(paidAmount).toFixed(2));
      const price = status === 'partially-paid' ?
        parseFloat(paidAmountValue - (paidAmountValue / 100))
        : parseFloat(parseFloat(priceLessPlatformFeeSc).toFixed(2));
      return total + price;
    }
    return total;
  }, 0);
  return sc.toFixed(2);
}

function* onGetListTransactionsFlow() {
  yield takeLatest(callGetListTransactions, function* onGetListTransactions() {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }

      const { uid } = auth;
      const txRef = firestore.collection('transactions');
      const snapshot = yield call(rsf.firestore.getCollection,
        txRef.where('uid', '==', uid).orderBy('created_at', 'desc'));

      const transactions = [];
      snapshot.forEach(doc => {
        const obj = doc.data();
        obj.invoice_status = 'pending';
        if (obj.paidVia === 'on-chain') {
          if (obj.confirmationSecureEmail) {
            obj.invoice_status = 'complete';
          } else if (obj.status === 'pending'
                     || obj.status === 'pending-partial') {
            obj.invoice_status = 'in progress';
          }
        } else if (obj.paidVia === 'lightning') {
          obj.invoice_status = 'complete';
        } else if (obj.paidVia === 'stable-coin') {
          obj.invoice_status = obj.status === 'paid'
            ? 'complete'
            : 'partially-paid';
        }

        obj.paid_via = obj.paidVia ? obj.paidVia : '';

        // if (obj.invoice_status === 'pending'
        //   && (moment().unix() - obj.created_at) > 900) {
        //   obj.invoice_status = 'expired';
        // }
        const format = 'D MMM, YYYY hh:mm:ss a';
        obj.created_at_formated = moment.unix(obj.created_at).format(format);
        obj.chainInvoice_settled_at_formated = (obj.chainInvoice.settled_at
          ? moment.unix(obj.chainInvoice.settled_at).format(format)
          : ''
        );
        obj.lightningInvoice_settled_at_formated = (obj.lightningInvoice
          && obj.lightningInvoice.settled_at
          ? moment.unix(obj.lightningInvoice.settled_at).format(format)
          : ''
        );
        obj.unitSale = obj.source_fiat_value;
        obj.id = doc.id;
        delete obj.image;
        transactions.push(obj);
      });

      const lastDay = moment(moment().subtract(1, 'day')).unix();
      const lastWeek = moment(moment().subtract(7, 'days')).unix();
      const lastMonth = moment(moment().subtract(30, 'days')).unix();
      const lastYear = moment(moment().startOf('year')).unix();
      const end = moment().unix();

      const btcLastDay = getPeriodData(lastDay, end, 'BTC', transactions);
      const btcLastWeek = getPeriodData(lastWeek, end, 'BTC', transactions);
      const btcLastMonth = getPeriodData(lastMonth, end, 'BTC', transactions);
      const btcLastYear = getPeriodData(lastYear, end, 'BTC', transactions);
      const usdtLastDay = getPeriodData(lastDay, end, 'USDt', transactions);
      const usdtLastWeek = getPeriodData(lastWeek, end, 'USDt', transactions);
      const usdtLastMonth = getPeriodData(lastMonth, end, 'USDt', transactions);
      const usdtLastYear = getPeriodData(lastYear, end, 'USDt', transactions);
      const busdLastDay = getPeriodData(lastDay, end, 'BUSD', transactions);
      const busdLastWeek = getPeriodData(lastWeek, end, 'BUSD', transactions);
      const busdLastMonth = getPeriodData(lastMonth, end, 'BUSD', transactions);
      const busdLastYear = getPeriodData(lastYear, end, 'BUSD', transactions);
      const usdcLastDay = getPeriodData(lastDay, end, 'USDC', transactions);
      const usdcLastWeek = getPeriodData(lastWeek, end, 'USDC', transactions);
      const usdcLastMonth = getPeriodData(lastMonth, end, 'USDC', transactions);
      const usdcLastYear = getPeriodData(lastYear, end, 'USDC', transactions);

      // yield put(setListTransactions({ ...transactions, loaded: true }));
      yield put(
        setListTransactions({
          btc: {
            last_day: bitcoinUtil.formatBTC(btcLastDay / 100000000),
            last_seven_days: bitcoinUtil.formatBTC(btcLastWeek / 100000000),
            last_thirty_days: bitcoinUtil.formatBTC(btcLastMonth / 100000000),
            this_year: bitcoinUtil.formatBTC(btcLastYear / 100000000),
          },
          mBtc: {
            last_day: bitcoinUtil.formatMBTC(btcLastDay / 100000),
            last_seven_days: bitcoinUtil.formatMBTC(btcLastWeek / 100000),
            last_thirty_days: bitcoinUtil.formatMBTC(btcLastMonth / 100000),
            this_year: bitcoinUtil.formatMBTC(btcLastYear / 100000),
          },
          sats: {
            last_day: bitcoinUtil.formatSats(btcLastDay),
            last_seven_days: bitcoinUtil.formatSats(btcLastWeek),
            last_thirty_days: bitcoinUtil.formatSats(btcLastMonth),
            this_year: bitcoinUtil.formatSats(btcLastYear),
          },
          usdt: {
            last_day: usdtLastDay,
            last_seven_days: usdtLastWeek,
            last_thirty_days: usdtLastMonth,
            this_year: usdtLastYear,
          },
          busd: {
            last_day: busdLastDay,
            last_seven_days: busdLastWeek,
            last_thirty_days: busdLastMonth,
            this_year: busdLastYear,
          },
          usdc: {
            last_day: usdcLastDay,
            last_seven_days: usdcLastWeek,
            last_thirty_days: usdcLastMonth,
            this_year: usdcLastYear,
          },
          loaded: true,
        }),
      );
      yield put(
        setPaymentsData({
          allData: transactions,
          ...filterPayments(transactions),
        }),
      );
    } catch (error) {
      yield put(setLoading(false));
      // h2o: swallowing error for now since it's not because
      // transacdtions have no price, it's osme other idiocy
      // https://neutronpay.atlassian.net/browse/PAYMT-83
      /* put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_GET_LIST_TRANSACTION',
        }),
      ); */
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onGetListAPIKeysFlow() {
  yield takeLatest(callGetListAPIKeys, function* onGetListAPIKeys() {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }

      const { uid } = auth;
      const txRef = firestore.collection('userApiKeys');
      const snapshot = yield call(rsf.firestore.getCollection,
        txRef.where('uid', '==', uid));

      const keys = [];
      snapshot.forEach(doc => {
        const obj = doc.data();
        obj.id = doc.id;
        obj.label = obj.type;
        keys.push(obj);
      });

      yield put(
        setListAPIKeys(keys),
      );
    } catch (error) {
      yield put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_GET_LIST_API_KEYS',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onGenerateAPIKeyFlow() {
  yield takeLatest(callGenerateAPIKey, function* onGenerateAPIKey({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }

      const { uid, type, companyName } = data;
      yield call(
        rsf.firestore.addDocument,
        'userApiKeys',
        {
          uid,
          type,
          apiKey: uuidv4(),
          permissions: 'read-only',
          companyName,
        },
      );

      yield put(
        notifySuccess({
          message: 'USER.SUCCESS_MESSAGE.GENERATED_API_KEY',
        }),
      );
      // eslint-disable-next-line no-unreachable
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_GENERATE_API_KEY',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onRevokeAPIKeyFlow() {
  yield takeLatest(callRevokeAPIKey, function* onRevokeAPIKey({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }

      const { uid } = auth;
      const { keyUid, apiKey } = data;
      const txRef = firestore.collection('userApiKeys');
      const rows = yield call(rsf.firestore.getCollection,
        txRef.where('uid', '==', uid).where('apiKey', '==', apiKey));

      let id = '';
      rows.forEach(doc => {
        if (keyUid === doc.id) {
          id = doc.id;
        }
      });
      yield call(rsf.firestore.deleteDocument, `userApiKeys/${id}`);

      yield put(
        notifySuccess({
          message: 'USER.SUCCESS_MESSAGE.REVOKE_API_KEY',
        }),
      );
      callback(true);
    } catch (error) {
      yield put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_REVOKE_API_KEY',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onUpdateInstantCashFlow() {
  yield takeLatest(callUpdateInstantCash, function* onUpdateInstantCash({
    payload: { status, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.uid) {
        throw new Error('Cannot get Authentication');
      }
      yield call(
        rsf.firestore.setDocument,
        `users/${auth.uid}`,
        { instantCashInd: status },
        { merge: true },
      );
      yield put(
        notifySuccess({
          message: 'USER.SUCCESS_MESSAGE.UPDATE_INSTANT_CASH_SUCCESS',
        }),
      );
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_UPDATE_INSTANT_CASH',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onConvertBTCToCashFlow() {
  yield takeLatest(callConvertBTCToCash, function* onConvertBTCToCash({
    payload: { instantCashPerc, amount, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (!auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken) {
        throw new Error('Cannot get Authentication');
      }
      const response = yield call(apiUtil.user.convertBTCToCash, {
        instantCashPerc,
        amount,
      });
      if (!response.success) {
        throw new Error(response.message);
      }
      yield put(callGetWalletBalance());
      yield put(
        notifySuccess({
          message: 'USER.SUCCESS_MESSAGE.CONVERT_TO_CASH_SUCCESS',
        }),
      );
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'USER.ERROR_MESSAGE.CANNOT_CONVERT_TO_CASH',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onConvertStablecoinToCashFlow() {
  yield takeLatest(callConvertStablecoinToCash,
    function* onConvertStablecoinToCash({
      payload: { instantCashPerc, amount, stablecoin, callback },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth || !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken) {
          throw new Error('Cannot get Authentication');
        }
        const response = yield call(apiUtil.user.convertStablecoinToCash, {
          instantCashPerc,
          stablecoin,
          amount,
        });
        if (!response.success) {
          throw new Error(response.message);
        }
        yield put(callGetWalletBalance());
        yield put(
          notifySuccess({
            message: 'USER.SUCCESS_MESSAGE.CONVERT_TO_CASH_SUCCESS',
          }),
        );
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message: 'USER.ERROR_MESSAGE.CANNOT_CONVERT_TO_CASH',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onConvertBTCToStablecoinFlow() {
  yield takeLatest(callConvertBTCToStablecoin,
    function* onConvertBTCToStablecoin({
      payload: { amount, stablecoin, callback },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth || !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken) {
          throw new Error('Cannot get Authentication');
        }
        const response = yield call(apiUtil.user.convertBTCToStablecoin, {
          amount,
          stablecoin,
        });

        if (!response.status) {
          throw new Error(response.message);
        }
        yield put(callGetWalletBalance());
        yield put(
          notifySuccess({
            message: 'USER.SUCCESS_MESSAGE.CONVERT_TO_STABLECOIN_SUCCESS',
          }),
        );
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message: 'USER.ERROR_MESSAGE.CANNOT_CONVERT_TO_STABLECOIN',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onUpdateInstantPercentageFlow() {
  yield takeLatest(callUpdateInstantPercentage,
    function* onUpdateInstantPercentage({
      payload: { instantCashPerc, callback = () => {} },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth || !auth.uid) {
          throw new Error('Cannot get Authentication');
        }

        yield call(
          rsf.firestore.setDocument,
          `users/${auth.uid}`,
          { instantCashPerc },
          { merge: true },
        );
        yield put(
          notifySuccess({
            message:
              'SETTINGS.PERSONAL.SUCCESS_MESSAGE.UPDATE_INFORMATION_SUCCESS',
          }),
        );
        callback(true);
      } catch (error) {
        callback(false);
        yield put(
          notifyError({
            message:
            'SETTINGS.PERSONAL.ERROR_MESSAGE.CANNOT_UPDATE_INFORMATION',
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onGetWebhookSettingFlow() {
  yield takeLatest(callGetWebhookSetting,
    function* onGetWebhookSetting() {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        const { uid } = auth;
        const webhookRef = firestore.collection('userDefinedWebhooks');
        const snapshot = yield call(rsf.firestore.getCollection,
          webhookRef.where('uid', '==', uid).orderBy('created_at', 'desc'));
        const webhookSettings = [];
        snapshot.forEach(doc => {
          const obj = doc.data();
          webhookSettings.push({
            endpoint: obj.endpoint,
            events: obj.events,
            id: doc.id,
            webhookSecret: obj.webhookSecret,
            created_at: obj.created_at,
          });
        });
        yield put(setWebhookSetting(webhookSettings));
      } catch (error) {
        yield put(
          notifyError({
            message: 'Cannot get the webhooks.',
            raw: true,
          }),
        );
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onAddWebhookSettingFlow() {
  yield takeLatest(callAddWebhookSetting,
    function* onAddWebhookSetting({
      payload: { data, callback },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        const { uid } = auth;
        const { endpoint, events } = data;
        const webhookRef = firestore.collection('userDefinedWebhooks');
        const snapshot = yield call(rsf.firestore.getCollection,
          webhookRef.where('uid', '==', uid)
            .where('endpoint', '==', endpoint)
            .orderBy('created_at', 'desc'));
        const webhookSettings = [];
        snapshot.forEach(doc => {
          const obj = doc.data();
          webhookSettings.push({
            endpoint: obj.endpoint,
            events: obj.events,
            id: doc.id,
            webhookSecret: obj.webhookSecret,
            created_at: obj.created_at,
          });
        });
        if (webhookSettings.length === 0) {
          const document = {
            uid,
            endpoint,
            events,
            created_at: moment().unix(),
            webhookSecret: uuidv4(),
          };
          yield call(
            rsf.firestore.addDocument,
            'userDefinedWebhooks',
            document,
          );
          yield put(
            notifySuccess({
              message: 'Webhook added successfully.',
              raw: true,
            }),
          );
          callback(true);
        } else {
          yield put(
            notifySuccess({
              message: 'Webhook endpoint already exists.',
              raw: true,
            }),
          );
          callback(false);
        }
      } catch (error) {
        yield put(
          notifyError({
            message: 'Cannot add the webhooks.',
            raw: true,
          }),
        );
        callback(false);
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onDeleteWebhookSettingFlow() {
  yield takeLatest(callDeleteWebhookSetting,
    function* onDeleteWebhookSetting({
      payload: { id, callback },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        yield call(
          rsf.firestore.deleteDocument,
          `userDefinedWebhooks/${id}`,
        );
        yield put(
          notifySuccess({
            message: 'Webhook deleted successfully.',
            raw: true,
          }),
        );
        callback(true);
      } catch (error) {
        yield put(
          notifyError({
            message: 'Cannot delete the webhook.',
            raw: true,
          }),
        );
        callback(false);
      } finally {
        yield put(setLoading(false));
      }
    });
}

function* onUpdateWebhookSettingFlow() {
  yield takeLatest(callUpdateWebhookSetting,
    function* onUpdateWebhookSetting({
      payload: { data, callback },
    }) {
      try {
        yield put(setLoading(true));
        const auth = yield select(getAuth);
        if (!auth ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
        ) {
          throw new Error('Cannot get Authentication');
        }
        const { endpoint, events, id } = data;
        yield call(
          rsf.firestore.updateDocument,
          `userDefinedWebhooks/${id}`,
          {
            endpoint,
            events,
          },
        );
        yield put(
          notifySuccess({
            message: 'Webhook updated successfully.',
            raw: true,
          }),
        );
        callback(true);
      } catch (error) {
        yield put(
          notifyError({
            message: 'Cannot update the webhook.',
            raw: true,
          }),
        );
        callback(false);
      } finally {
        yield put(setLoading(false));
      }
    });
}
export default function* saga() {
  yield all([
    onGetWalletBalanceFlow(),
    onConvertBTCToCashFlow(),
    onConvertStablecoinToCashFlow(),
    onConvertBTCToStablecoinFlow(),
    onUpdateInstantCashFlow(),
    onGetListTransactionsFlow(),
    onGetListAPIKeysFlow(),
    onGenerateAPIKeyFlow(),
    onRevokeAPIKeyFlow(),
    onUpdateInstantPercentageFlow(),
    onGetWebhookSettingFlow(),
    onAddWebhookSettingFlow(),
    onDeleteWebhookSettingFlow(),
    onUpdateWebhookSettingFlow(),
  ]);
}
