import { takeLatest, all, put, call } from 'redux-saga/effects';

import { apiUtil } from '../../utils';
import { setLoading } from '../../redux/ducks/loading.duck';
import { notifyError } from '../../redux/ducks/notification.duck';
// import { rsf, firestore } from '../../services/firebase';
// import { getCurrentUserProfile } from '../ducks/auth.duck';
import {
  callGetInfo,
  callGetDetails,
  setDetails,
  setInfo,
} from './checkout.duck';

function* onGetDetailsFlow() {
  yield takeLatest(callGetDetails, function* onGetDetails(
    { payload: { id, callback } },
  ) {
    try {
      yield put(setLoading(true));
      const invoice = yield call(
        apiUtil.checkout.getInfo,
        id,
      );

      yield put(setDetails({ details: invoice.data }));
      callback(true);
    } catch (error) {
      yield put(
        notifyError({ message: 'BITCOIN.ERROR_MESSAGE.CANNOT_GET_RATES' }),
      );
      callback(false);
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onGetInfoFlow() {
  yield takeLatest(callGetInfo, function* onGetInfo(
    { payload: { id, callback } },
  ) {
    try {
      const invoiceStatus = yield call(
        apiUtil.checkout.getStatus,
        id,
      );

      yield put(setInfo({ info: invoiceStatus.data }));
      callback(invoiceStatus.data);
    } catch (error) {
      yield put(
        notifyError({ message: 'BITCOIN.ERROR_MESSAGE.CANNOT_GET_RATES' }),
      );
      callback(false);
    }
  });
}

export default function* saga() {
  yield all([
    onGetDetailsFlow(),
    onGetInfoFlow(),
  ]);
}
