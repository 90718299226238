/**
 * Static values only.  Dynamically generated ones should be placed in env.js
 */
export const APP_NAME_PREFIX = 'neutronpay';

/** ********** DEFAULT LANGUAGE ********* */
export const DEFAULT_LOCALE = 'en';

/** ********** DURATION/TIMEOUT ********* */
export const FETCHING_TIMEOUT = 60 * 1000; // 60 seconds
export const RESEND_EMAIL_TIMEOUT = 60; // 60 seconds
/**
 * See how to set less than a day
 * https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-make-the-cookie-expire-in-less-than-a-day
 */
export const TOKEN_2FA_EXPIRED_IN = 5; // 5 days
export const TOAST_NOTIFICATION_DURATION = 5 * 1000; // 5 seconds

/** ********** NEUTRONPAY INTERNAL ********* */
export const EMAIL_SUPPORT = 'support@neutronpay.com';
export const EMAIL_INFORMATION = 'info@neutronpay.com';

/** ********** EXTERNAL LINKS ********* */
/**
 * TODO:
 * Reference deployed URL instead of hard coding URL name
 */
/*
export const LINKS = {
  LINK_API_DOCS: 'https://docs.neutronpay.com/?version=latest',
  LINK_SHOPPING: 'https://' + process.env.NP_BASE_URL + '/shopping/KILHmdUh5nQvLscN0XDQm1wkzaN2',
  LINK_DONATION: 'https://' + process.env.NP_BASE_URL + '/payments/',
  LINK_LOGIN: 'https://' + process.env.NP_BASE_URL + '/sign-in',
  LINK_SIGNUP: 'https://' + process.env.NP_BASE_URL + '/sign-up',
};*/

/** ********** QR CODE MONKEY ********** */
export const QR_API_URL = 'https://qrcode-monkey.p.rapidapi.com/qr';
export const QR_API_KEY = '';

/** ********** OTHERS ********* */
/** Limit categories can be created. See Invoices manage categories UI */
export const MAXIMUM_INVOICES_CATEGORIES = 10;
