import React from 'react';
import PropTypes from 'prop-types';
import preset from 'jss-preset-default';
import { JssProvider, jss } from 'react-jss';
import { IntlProvider } from 'react-intl-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Provider, ReactReduxContext } from 'react-redux';

import history from '../history';
import store from '../redux/store';
import { jssUtil } from '../utils';

import FirebaseProvider from './FirebaseProvider';
import NotificationProvider from './NotificationProvider';

jss.setup(preset());

const AppProvider = ({ children }) => (
  <Provider store={store} context={ReactReduxContext}>
    <ConnectedRouter history={history} context={ReactReduxContext}>
      <IntlProvider>
        <JssProvider
          jss={jss}
          classNamePrefix="neutron-"
          generateId={jssUtil.generateClassName}
        >
          <NotificationProvider>
            <FirebaseProvider store={store}>{children}</FirebaseProvider>
          </NotificationProvider>
        </JssProvider>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>
);

AppProvider.propTypes = { children: PropTypes.node.isRequired };

export default AppProvider;
