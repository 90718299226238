/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/PAYOUTS/TRANSACTIONS';
const defaultSearchCriteria = {
  page: 1, // TODO integration: check with API param/query
  limit: 10, // TODO integration: check with API param/query - row per page
  time: undefined, // TODO integration: check with API param/query
  search: '', // TODO integration: check with API param/query
};
const initialStates = {
  isFetching: false,
  // TODO check search on BE or FE then remove or not remove `allData`
  // Currently, all data are pulled and search creteria will be done in FE
  // If we change to search via API
  // just remove `allData` and everthing relate, use only `list`
  allData: [],
  list: [],
  csvData: [],
  tab: 0,
  totalRecords: 0,
  searchCriteria: defaultSearchCriteria,
  fee: {
    bitcoin: 0,
    stableCoin: 0,
  },
};

export const callGetListTransactions = createAction(`${PREFIX}/GET_LIST_TRANSACTIONS`);
export const callSyncTransactions = createAction(`${PREFIX}/SYNC_TRANSACTIONS`);
export const callGetFeeData = createAction(`${PREFIX}/GET_FEE_DATA`);
export const setTransactionsData = createAction(`${PREFIX}/SET_LIST_DATA`);

// TODO: remove `getAllTransactions` if no need
export const getAllTransactions = (state) => state.payouts.transactions.allData;
export const getListTransactions = (state) => state.payouts.transactions.list;
export const getFetching = (state) => state.payouts.transactions.isFetching;
export const getTotalRecords = (state) => state.payouts.transactions.totalRecords;
export const getSearchCriteria = (state) => state.payouts.transactions.searchCriteria;
export const getTab = (state) => state.payouts.transactions.tab;
export const getCSVData = (state) => state.payouts.transactions.csvData;
export const getFeeData = (state) => state.payouts.transactions.fee;

// Currently, all data are pulled and search creteria will be done in FE
// If we change to search via API, just remove this and everthing relate
export const filterTransactions = (data, criteria = defaultSearchCriteria) => {
  let result = [...data];
  const { page, limit, time, search } = criteria;

  // Time
  if (time) {
    const today = Date.now();
    switch (time) {
      case 'lastWeek': {
        const lastSevenDays = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastSevenDays / 1000);
        break;
      }
      case 'lastMonth': {
        const lastThirtyDays = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastThirtyDays / 1000);
        break;
      }
      default: {
        if (typeof time === 'object' && time.from && time.to) {
          result = result.filter(item =>
            item.created_at >= time.from.getTime() / 1000 &&
            item.created_at <= time.to.getTime() / 1000);
        }
        break;
      }
    }
  }

  // Search
  if (search) {
    result = result.filter(
      (item) => {
        const { status, created } = item;
        const RegString = `(${search.toLowerCase()})+`;
        const Reg = new RegExp(RegString, 'gi');
        return (status.toLowerCase().includes(search.toLowerCase()) ||
        Reg.test(created));
      },
    );
  }

  const csvData = result.map(item => {
    const { created, coinType, status, totalAmount, feeAmount } = item;
    return {
      date: created,
      method: coinType === 'usdt' ? 'USDT' : coinType.toUpperCase(),
      amount: totalAmount,
      fee: feeAmount,
      total: parseFloat(feeAmount + parseFloat(totalAmount)),
      status,
    };
  });
  result = result.slice((page - 1) * limit, page * limit);

  return {
    list: result,
    csvData,
    totalRecords: result.length,
  };
};

export default handleActions(
  new Map([
    [
      setTransactionsData,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
  ]),
  { ...initialStates },
);
