// Fix JSS class name generated conflict with production build https://github.com/marmelab/react-admin/issues/1782
const escapeRegex = /([[\].#*$><+~=|^:(),"'`\s])/g;
let classCounter = 0;

// Heavily inspired of Material UI:
// @see https://github.com/mui-org/material-ui/blob/9cf73828e523451de456ba3dbf2ab15f87cf8504/src/styles/createGenerateClassName.js
// The issue with the MUI function is that is create a new index for each
// new `withStyles`, so we handle have to write our own counter
const generateClassName = (rule, styleSheet) => {
  classCounter += 1;
  let result = `${rule.key}-${classCounter}`;

  if (process.env.NODE_ENV === 'production') {
    result = `c${classCounter}`;
  }

  if (styleSheet && styleSheet.options.classNamePrefix) {
    let prefix = styleSheet.options.classNamePrefix;
    // Sanitize the string as will be used to prefix the generated class name.
    prefix = prefix.replace(escapeRegex, '-');

    if (prefix.match(/^Mui/)) {
      result = `${prefix}-${rule.key}`;
    }

    result = `${prefix}-${rule.key}-${classCounter}`;
  }

  return result.replace(/\$/g, '');
};

export const rem = (sizeInPx, withUnit = true, baseSize = 16) =>
  (parseFloat(sizeInPx) / baseSize).toString().concat(!withUnit ? '' : 'rem');

export const hexToRgbA = (hex, opacity) => {
  let char;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    char = hex.substring(1).split('');
    if (char.length === 3) {
      char = [char[0], char[0], char[1], char[1], char[2], char[2]];
    }
    char = `0x${char.join('')}`;

    const alpha = !opacity || opacity < 0 || opacity > 1 ? 1 : opacity;

    /* eslint-disable no-bitwise */
    return `rgba(${[
      (char >> 16) & 255,
      (char >> 8) & 255,
      char & 255,
      alpha,
    ].join(',')})`;
  }
  throw new Error('Bad Hex');
};

export default {
  generateClassName,
  rem,
  hexToRgbA,
};
