import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';

import Firebase from '../services/firebase';
import Loading from '../components/Loading';
import { getAuth } from '../redux/ducks/auth.duck';

const AuthIsLoaded = ({ children }) => {
  const auth = useSelector(getAuth, shallowEqual);

  return !isLoaded(auth) ? <Loading open /> : children;
};
AuthIsLoaded.propTypes = { children: PropTypes.node.isRequired };

const FirebaseProvider = ({ children, store }) => {
  const config = useMemo(
    () => ({
      userProfile: 'users',
      useFirestoreForProfile: true,
      // enableClaims: true // Get custom claims along with the profile
    }),
    [],
  );

  return (
    <ReactReduxFirebaseProvider
      firebase={Firebase}
      config={config}
      dispatch={store.dispatch}
    >
      <AuthIsLoaded>{children}</AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  );
};
FirebaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
  }).isRequired,
};

export default FirebaseProvider;
