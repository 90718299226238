import React, { useState, useMemo } from 'react';

import { useEventListener } from '../hooks';
import BREAKPOINTS from '../constants/BREAKPOINTS';

/**
 * @function switchMobileWeb
 * @param {*} WebComponent
 * @param {*} MobileComponent
 * @param {*} breakpoint
 * @description return `MobileComponent` if current screen width smaller than `breakpoint`
 *  and `WebComponent` if current screen width larger than `breakpoint`
 */
const switchMobileWeb = (
  WebComponent,
  MobileComponent,
  breakpoint = BREAKPOINTS.maxSm,
) => props => {
  const [isMobile, setMobileScreen] = useState(window.innerWidth <= breakpoint);

  useEventListener(
    'resize',
    () => {
      if (window.innerWidth <= breakpoint && !isMobile) {
        setMobileScreen(true);
      } else if (window.innerWidth > breakpoint && isMobile) {
        setMobileScreen(false);
      }
    },
    true,
  );

  return !isMobile ? (
    <WebComponent {...props} />
  ) : (
    <MobileComponent {...props} />
  );
};

export default (...arg) =>
  useMemo(() => switchMobileWeb(...arg), [window.innerWidth, ...arg]);
