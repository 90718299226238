import { takeLatest, fork, all, put, call, select } from 'redux-saga/effects';

// import axios from 'axios';
import { setLoading } from '../../../redux/ducks/loading.duck';
import { notifyError } from '../../../redux/ducks/notification.duck';
import { getAuth } from '../../../redux/ducks/auth.duck';
import transactions from './ListPayouts/listPayouts.saga';
import { apiUtil } from '../../../utils';
import {
  callPayoutsVerifyOTP,
  callPayoutsCreatePAYOUTS,
} from './payouts.duck';

function* onPayoutsVerifyOTPFlow() {
  yield takeLatest(callPayoutsVerifyOTP, function* onWithdrawVerifyOTP({
    payload: { otp, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }
      const response = yield call(apiUtil.authentication.verify2FAOTP, {
        token: auth.stsTokenManager.accessToken,
        otp,
      });
      if (!response.success) {
        throw new Error(response.message);
      }
      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({
          message: 'WITHDRAWALS.ERROR_MESSAGE.VERIFY_OTP_FAILED',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

function* onPayoutsCreatePayoutsFlow() {
  yield takeLatest(callPayoutsCreatePAYOUTS, function*
  onSendRequestPayoutsCreatePayouts({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      const auth = yield select(getAuth);
      if (
        !auth ||
          !auth.uid ||
          !auth.stsTokenManager ||
          !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }

      const response = yield call(apiUtil.payouts.createRequest, data);

      if (!response.success) {
        throw new Error(response.message);
      }

      callback(true);
    } catch (error) {
      callback(false);
      yield put(
        notifyError({ message: error.message }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* sagas() {
  yield fork(transactions);
  yield fork(function* payouts() {
    yield all([
      onPayoutsVerifyOTPFlow(),
      onPayoutsCreatePayoutsFlow(),
    ]);
  });
}
