/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/WITHDRAWALS/CONVERT';
const defaultSearchCriteria = {
  page: 1, // TODO integration: check with API param/query
  limit: 10, // TODO integration: check with API param/query - row per page
  time: undefined, // TODO integration: check with API param/query
  search: '', // TODO integration: check with API param/query
};
const initialStates = {
  isFetching: false,
  // TODO check search on BE or FE then remove or not remove `allData`
  // Currently, all data are pulled and search creteria will be done in FE
  // If we change to search via API
  // just remove `allData` and everthing relate, use only `list`
  allData: [],
  list: [],
  csvData: [],
  tab: 0,
  totalRecords: 0,
  searchCriteria: defaultSearchCriteria,
};

export const callGetHistoryTransactions = createAction(`${PREFIX}/GET_HISTORY_TRANSACTIONS`);
export const callSyncHistoryTransactions = createAction(`${PREFIX}/SYNC_HISTORY_TRANSACTIONS`);

export const setHistoryTransactionsData = createAction(`${PREFIX}/SET_HISTORY_LIST_DATA`);

// TODO: remove `getAllTransactions` if no need
export const getAllHistoryTransactions = (state) => state.withdrawals.convertHistory.allData;
export const getHistoryListTransactions = (state) => state.withdrawals.convertHistory.list;
export const getHistoryFetching = (state) => state.withdrawals.convertHistory.isFetching;
export const getHistoryTotalRecords = (state) => state.withdrawals.convertHistory.totalRecords;
export const getHistorySearchCriteria = (state) => state.withdrawals.convertHistory.searchCriteria;
// export const getTab = (state) => state.withdrawals.transactions.tab;
export const getHistoryCSVData = (state) => state.withdrawals.convertHistory.csvData;

// Currently, all data are pulled and search creteria will be done in FE
// If we change to search via API, just remove this and everthing relate
export const filterTransactions = (data, criteria = defaultSearchCriteria) => {
  if (!data || data.length === 0) return { list: [], csvData: [] };

  let result = [...data];
  const { page, limit, time, search } = criteria;
  // Time
  if (time) {
    const today = Date.now();
    switch (time) {
      case 'lastWeek': {
        const lastSevenDays = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastSevenDays / 1000);
        break;
      }
      case 'lastMonth': {
        const lastThirtyDays = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime();
        result = result.filter(item => item.created_at >= lastThirtyDays / 1000);
        break;
      }
      default: {
        if (typeof time === 'object' && time.from && time.to) {
          result = result.filter(item =>
            item.created_at >= time.from.getTime() / 1000 &&
            item.created_at <= time.to.getTime() / 1000);
        }
        break;
      }
    }
  }

  // Search
  if (search) {
    result = result.filter(
      (item) =>
        (
          (
            item.from &&
            item.from.toLowerCase().includes(search.toLowerCase())
          ) ||
          (
            item.to &&
            item.to.toLowerCase().includes(search.toLowerCase())
          ) ||
          (
            item.total &&
            item.total.toString() === search
          )
        ),
    );
  }

  const csvData = result.map(item => {
    const { total, from, to, created } = item;
    return {
      date: created,
      from,
      to,
      total,
    };
  });
  result = result.slice((page - 1) * limit, page * limit);

  return {
    list: result,
    csvData,
  };
};

export default handleActions(
  new Map([
    [
      setHistoryTransactionsData,
      (state, { payload }) => ({
        ...state,
        ...payload,
      }),
    ],
  ]),
  { ...initialStates },
);
