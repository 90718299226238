import { takeLatest, all, put, delay, select, call } from 'redux-saga/effects';
import moment from 'moment';

// import { apiUtil } from '../../utils';
import { getAuth } from '../ducks/auth.duck';
import { setLoading } from '../ducks/loading.duck';
import { notifyError } from '../ducks/notification.duck';
import { rsf, firestore } from '../../services/firebase';
import {
  callGetListPayments,
  callDownloadPayments,
  getSearchCriteria,
  getAllPayments,
  setPaymentsData,
  filterPayments,
  callUpdateListPayments,
} from '../ducks/payments.duck';

function* onGetListPaymentsFlow() {
  yield takeLatest(callGetListPayments, function* onGetListPayments({
    payload,
  }) {
    yield put(setPaymentsData({ isFetching: true }));
    const searchCriteria = yield select(getSearchCriteria);
    const newSearchCriteria = { ...searchCriteria, ...payload };
    // Currently, all data are pulled and search creteria will be done in FE
    // If we change to search via API, just change flow in this saga
    const currentData = yield select(getAllPayments);
    const auth = yield select(getAuth);
    if (
      !auth || !auth.stsTokenManager || !auth.stsTokenManager.accessToken
    ) {
      throw new Error('Cannot get Authentication');
    }
    try {
      if (currentData && currentData.length > 0) {
        yield put(
          setPaymentsData({
            isFetching: false,
            searchCriteria: newSearchCriteria,
            ...filterPayments(currentData, newSearchCriteria),
          }),
        );
      } else {
        const txRef = firestore.collection('transactions');
        const snapshot = yield call(rsf.firestore.getCollection,
          txRef.where('uid', '==', auth.uid)
            .orderBy('created_at', 'desc'));

        yield put(callUpdateListPayments({ ...payload, snapshot }));
      }
    } catch (error) {
      yield put(
        setPaymentsData({
          allData: [],
          list: [],
          totalRecords: 0,
          isFetching: false,
          searchCriteria: newSearchCriteria,
        }),
      );
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_GET_LIST' }),
      );
    }
  });
}

function* onUpdateListPaymentFlow() {
  yield takeLatest(callUpdateListPayments,
    // eslint-disable-next-line max-len
    function* onUpdateListPayments({ payload }) {
      const searchCriteria = yield select(getSearchCriteria);
      const newSearchCriteria = { ...searchCriteria, ...payload };

      const items = [];
      const format = 'D MMM, YYYY hh:mm:ss a';
      payload.snapshot.forEach(doc => {
        const obj = doc.data();
        if (Object.prototype.hasOwnProperty.call(obj, 'deleted_at') &&
          obj.deleted_at) {
          return;
        }

        if (obj.status === 'pending' || obj.status === 'pending-partial') {
          obj.invoice_status = 'in progress';
        } else if (obj.status === 'paid') {
          obj.invoice_status = 'complete';
        } else {
          obj.invoice_status = obj.status;
        }

        obj.created_at_formated = moment.unix(obj.created_at).format(format);
        obj.chainInvoice_settled_at_formated = (
          obj.chainInvoice.settled_at
            ? moment.unix(obj.chainInvoice.settled_at).format(format)
            : ''
        );
        obj.lightningInvoice_settled_at_formated = (
          obj.lightningInvoice && obj.lightningInvoice.settled_at
            ? moment.unix(obj.lightningInvoice.settled_at).format(format)
            : ''
        );

        obj.unitSale = obj.source_fiat_value;
        obj.id = doc.id;
        delete obj.image;
        items.push(obj);
      });
      yield put(
        setPaymentsData({
          allData: items,
          isFetching: false,
          searchCriteria: newSearchCriteria,
          ...filterPayments(items, newSearchCriteria),
        }),
      );
    });
}

function* onDownloadPaymentsFlow() {
  yield takeLatest(callDownloadPayments, function* onDownloadPayments() {
    try {
      yield put(setLoading(true));
      yield delay(5000);
      throw new Error();
      // TODO integration: call download payments
    } catch (error) {
      yield put(
        notifyError({ message: 'PAYMENTS.ERROR_MESSAGE.CANNOT_DOWNLOAD' }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* saga() {
  yield all([
    onGetListPaymentsFlow(),
    onDownloadPaymentsFlow(),
    onUpdateListPaymentFlow(),
  ]);
}
