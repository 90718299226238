import { takeLatest, all, put, call, delay } from 'redux-saga/effects';

import { apiUtil } from '../../utils';
import { setLoading } from '../../redux/ducks/loading.duck';
import { notifyError } from '../../redux/ducks/notification.duck';

import { callCreateTransaction } from './donation.duck';

function* onCreateTransactionFlow() {
  yield takeLatest(callCreateTransaction, function* onCreateTransaction({
    payload,
  }) {
    try {
      yield put(setLoading(true));
      // TODO integration
      // console.log(payload);
      yield delay(5000);
      throw new Error('TODO');
      // eslint-disable-next-line no-unreachable
      yield call(apiUtil.donation.createTransaction, payload);
    } catch (error) {
      yield put(
        notifyError({
          message: 'DONATION.ERROR_MESSAGE.CANNOT_CREATE_TRANSACTION',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* saga() {
  yield all([onCreateTransactionFlow()]);
}
