/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/INVOICES/MANAGE_ITEMS';
const defaultSearchCriteria = {
  page: 1, // TODO integration: check with API param/query
  limit: 10, // TODO integration: check with API param/query - row per page
  category: 'all', // TODO integration: check with API param/query
  search: '', // TODO integration: check with API param/query
};
const initialStates = {
  isFetching: false,
  list: [],
  totalRecords: 0,
  searchCriteria: defaultSearchCriteria,
  itemModal: { mode: undefined, open: false, details: undefined },
  categories: [],
};

export const callGetListItems = createAction(`${PREFIX}/GET_LIST_ITEMS`);
export const callCreateItem = createAction(`${PREFIX}/CREATE_ITEM`);
export const callUpdateItem = createAction(`${PREFIX}/UPDATE_ITEM`);
export const callDeleteItem = createAction(`${PREFIX}/DELETE_ITEM`);
export const callGetListCategories = createAction(`${PREFIX}/GET_LIST_CATEGORIES`);
export const callCreateCategory = createAction(`${PREFIX}/CREATE_CATEGORY`);
export const callUpdateCategory = createAction(`${PREFIX}/UPDATE_CATEGORY`);
export const callDeleteCategory = createAction(`${PREFIX}/DELETE_CATEGORY`);
export const callUpdateListCategories = createAction(`${PREFIX}/UPDATE_CATEGORIES`);

export const resetSearchCriteria = createAction(`${PREFIX}/RESET_SEARCH_CRITERIA`);
export const setListItems = createAction(`${PREFIX}/SET_LIST_DATA`);
export const setItemModal = createAction(`${PREFIX}/SET_ITEM_MODAL`);

export const getAuth = (state) => state.firebase.auth;
export const getListItems = (state) => state.invoices.manageItems.list;
export const getListCategories = (state) => state.invoices.manageItems.categories;
export const getFetching = (state) => state.invoices.manageItems.isFetching;
export const getTotalRecords = (state) => state.invoices.manageItems.totalRecords;
export const getSearchCriteria = (state) => state.invoices.manageItems.searchCriteria;
export const getItemModal = (state) => state.invoices.manageItems.itemModal;

export default handleActions(
  new Map([
    [
      resetSearchCriteria,
      (state) => ({
        ...state,
        searchCriteria: defaultSearchCriteria,
      }),
    ],
    [
      setListItems,
      (state, { payload }) => ({ ...state, ...payload }),
    ],
    [
      setItemModal,
      (state, { payload }) => ({
        ...state,
        itemModal: { ...payload },
      }),
    ],
  ]),
  { ...initialStates },
);
