import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'DASHBOARD/PAYOUTS/SUBTABS';
const initialStates = {
  selectedTab: {
    index: 0,
    data: {},
  },
};

export const setSelectedSubTab = createAction(`${PREFIX}/SET_SELECTED_TAB`);

export const getSelectedSubTab = (state) => state.payouts.subTabs.selectedTab;

export default handleActions(
  new Map([
    [
      setSelectedSubTab,
      (state, { payload }) => ({
        ...state,
        selectedTab: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
