/* eslint-disable max-len */
import { createAction, handleActions } from 'redux-actions';

const PREFIX = 'USER';
const initialStates = {
  walletBalance: {
    sats_bal: 0,
    btc_bal: 0,
    mBtc_bal: 0,
    usdt_bal: 0,
    busd_bal: 0,
    usdc_bal: 0,
    cash_bal: 0,
    currency: 'USD',
  },
  transactions: {
    btc: { last_day: 0, last_seven_days: 0, last_thirty_days: 0, this_year: 0 },
    mBtc: { last_day: 0, last_seven_days: 0, last_thirty_days: 0, this_year: 0 },
    sats: { last_day: 0, last_seven_days: 0, last_thirty_days: 0, this_year: 0 },
    usdt: { last_day: 0, last_seven_days: 0, last_thirty_days: 0, this_year: 0 },
    busd: { last_day: 0, last_seven_days: 0, last_thirty_days: 0, this_year: 0 },
    usdc: { last_day: 0, last_seven_days: 0, last_thirty_days: 0, this_year: 0 },
    loaded: false,
  },
  webhooks: [],
  apiKeys: [],
};

export const callGetWalletBalance = createAction(`${PREFIX}/GET_WALLET_BALANCE`);
export const callGetListTransactions = createAction(`${PREFIX}/GET_LIST_TRANSACTIONS`);
export const callUpdateInstantCash = createAction(`${PREFIX}/UPDATE_INSTANT_CASH`);
export const callConvertBTCToCash = createAction(`${PREFIX}/CONVERT_BTC_TO_CASH`);
export const callConvertStablecoinToCash = createAction(`${PREFIX}/CONVERT_STABLECOIN_TO_CASH`);
export const callConvertBTCToStablecoin = createAction(`${PREFIX}/CONVERT_BTC_TO_STABLECOIN`);
export const callGetListAPIKeys = createAction(`${PREFIX}/GET_LIST_API_KEYS`);
export const callGenerateAPIKey = createAction(`${PREFIX}/GENERATE_API_KEY`);
export const callRevokeAPIKey = createAction(`${PREFIX}/REVOKE_API_KEY`);
export const callUpdateInstantPercentage = createAction(`${PREFIX}/UPDATE_INSTANT_PERCENTAGE`);
export const callGetWebhookSetting = createAction(`${PREFIX}/GET_WEBHOOK_SETTINGS`);
export const callAddWebhookSetting = createAction(`${PREFIX}/ADD_WEBHOOK_SETTINGS`);
export const callUpdateWebhookSetting = createAction(`${PREFIX}/UPDATE_WEBHOOK_SETTINGS`);
export const callDeleteWebhookSetting = createAction(`${PREFIX}/DELETE_WEBHOOK_SETTINGS`);

export const setWalletBalance = createAction(`${PREFIX}/SET_WALLET_BALANCE`);
export const setListTransactions = createAction(`${PREFIX}/SET_LIST_TRANSACTIONS`);
export const setListAPIKeys = createAction(`${PREFIX}/SET_LIST_API_KEYS`);
export const setCheckoutUser = createAction(`${PREFIX}/SET_CHECKOUT_USER`);
export const setWebhookSetting = createAction(`${PREFIX}/SET_WEBHOOK_SETTINGS`);

export const getWalletBalance = (state) => state.user.walletBalance;
export const getListTransactions = (state) => state.user.transactions;
export const getListAPIKeys = (state) => state.user.apiKeys;
export const getCheckoutUser = (state) => state.user.checkoutUser;

export const getWebhookSettings = (state) => state.user.webhooks;

export default handleActions(
  new Map([
    [
      setCheckoutUser,
      (state, { payload }) => ({
        ...state,
        checkoutUser: { ...state.checkoutUser, ...payload },
      }),
    ],
    [
      setWalletBalance,
      (state, { payload }) => ({
        ...state,
        walletBalance: { ...state.walletBalance, ...payload },
      }),
    ],
    [
      setListTransactions,
      (state, { payload }) => ({
        ...state,
        transactions: { ...state.transactions, ...payload },
      }),
    ],
    [
      setListAPIKeys,
      (state, { payload }) => ({
        ...state,
        apiKeys: payload,
      }),
    ],
    [
      setWebhookSetting,
      (state, { payload }) => ({
        ...state,
        webhooks: payload,
      }),
    ],
  ]),
  { ...initialStates },
);
