import {
  takeLatest, all, put, select, takeEvery, call,
} from 'redux-saga/effects';
import { setLoading } from '../ducks/loading.duck';
import { apiUtil } from '../../utils';

import { getAuth } from '../ducks/auth.duck';
import {
  getCartItems,
  setCartItems,
  addCartItem,
  removeCartItem,
  setCartTotal,
  getCartCoupons,
  addCartDiscount,
  removeCartDiscount,
  setCartDiscount,
  callSaveCart,
  setCartCurrency,
} from '../ducks/cart.duck';
import {
  notifyError,
  notifySuccess,
} from '../ducks/notification.duck';

/*
* Add cart items
*/

function* onAddCartItemFlow() {
  yield takeEvery(addCartItem, function* onAddCartItem({ payload }) {
    const { id, name, price, currency } = payload;
    const cartItems = yield select(getCartItems);
    const discount = yield select(getCartCoupons);
    let discounts = [...discount];

    if (cartItems.length === 0) {
      yield put(setCartCurrency(currency));
    }

    const cartItemsIndex = cartItems.findIndex((el) => el.id === id);
    const cart = [...cartItems];
    if (cartItemsIndex > -1) {
      cart[cartItemsIndex].quantity += 1;
    } else {
      const payloadItem = { id, name, price, quantity: 1, currency };
      cart.push(payloadItem);
    }
    // calculate total
    // eslint-disable-next-line max-len
    const total = [...cart].map((el) => parseFloat(el.quantity) * parseFloat(el.price))
      .reduce((pre, curr) => parseFloat(pre) + parseFloat(curr));
    // eslint-disable-next-line no-unused-vars
    let totalAmount = total;
    if (discount && discount.length > 0) {
      discounts = discounts.map((discountItem) => {
        let discountAmount = 0;
        const { type, value, quantity } = discountItem;
        const discountType = type || null;
        const discountValue = value || 0;
        const discountQuantity = quantity || 0;
        switch (discountType) {
          case 'percentage': {
            discountAmount = (parseFloat(
              totalAmount / 100,
            ) * discountValue).toFixed(2);
            totalAmount -= discountAmount * discountQuantity;
            break;
          }
          case 'amount': {
            discountAmount = (discountValue).toFixed(2);
            totalAmount -= discountAmount * discountQuantity;
            break;
          }
          default:
            totalAmount -= 0;
        }
        return { ...discountItem,
          price: parseFloat(discountAmount),
          value: parseFloat(value),
        };
      });
    }
    yield put(setCartDiscount(discounts));
    yield put(setCartItems(cart));
    yield put(setCartTotal(parseFloat(totalAmount.toFixed(2))));
  });
}

/*
* Remove cart items
*/

function* onRemoveCartItemFlow() {
  yield takeLatest(removeCartItem, function* onRemoveCartItem({ payload }) {
    const { id } = payload;
    const cartItems = yield select(getCartItems);
    const discounts = yield select(getCartCoupons);
    let cart = [...cartItems];
    let discount = [...discounts];
    const cartItemIndex = cartItems.findIndex((el) => el.id === id);
    if (cartItemIndex > -1) {
      const { quantity } = cartItems[cartItemIndex];
      if (quantity > 1) {
        cart = cart.map((el) => {
          if (el.id === id) {
            return { ...el, quantity: el.quantity - 1 };
          }
          return el;
        });
      } else {
        cart.splice(cartItemIndex, 1);
      }
    }
    // eslint-disable-next-line max-len
    const total = cart.length > 0 ? [...cart]
      .map((el) => parseFloat(el.quantity) * parseFloat(el.price))
      .reduce((pre, curr) => parseFloat(pre) + parseFloat(curr))
      : 0;
    // eslint-disable-next-line no-unused-vars
    let totalAmount = total;
    if (discount && discount.length > 0) {
      discount = discount.map((discountItem) => {
        let discountAmount = 0;
        const { type, value, quantity } = discountItem;
        const discountType = type || null;
        const discountValue = value || 0;
        const discountQuantity = quantity || 0;
        switch (discountType) {
          case 'percentage': {
            discountAmount = (parseFloat(
              totalAmount / 100,
            ) * discountValue).toFixed(2);
            totalAmount -= discountAmount * discountQuantity;
            break;
          }
          case 'amount': {
            discountAmount = (discountValue).toFixed(2);
            totalAmount -= discountAmount * discountQuantity;
            break;
          }
          default:
            totalAmount -= 0;
        }
        return {
          ...discountItem,
          price: parseFloat(discountAmount),
          value: parseFloat(value),
        };
      });
    }
    const cartTotal = totalAmount > 0 ? totalAmount.toFixed(2) : 0;
    yield put(setCartDiscount(discount));
    yield put(setCartItems(cart));
    yield put(setCartTotal(parseFloat(cartTotal)));
  });
}

/*
* Add discount
*/

function* onAddDiscountCouponFlow() {
  yield takeEvery(addCartDiscount, function* onaddCartDiscount({ payload }) {
    const { id, name, value, type, currency } = payload;
    const discounts = yield select(getCartCoupons);
    const cartItems = yield select(getCartItems);
    let discount = [...discounts];
    const discountIndex = discount.findIndex((el) => el.id === id);
    if (discountIndex > -1) {
      discount[discountIndex].quantity += 1;
    } else {
      const payloadItem = {
        id, name, quantity: 1, currency, value, type };
      discount.push(payloadItem);
    }
    const total = cartItems.length > 0 ? [...cartItems]
      .map((el) => parseFloat(el.quantity) * parseFloat(el.price))
      .reduce((pre, curr) => parseFloat(pre) + parseFloat(curr))
      : 0;
    // eslint-disable-next-line no-unused-vars
    let totalAmount = total;
    if (discount && discount.length > 0) {
      discount = discount.map((discountItem) => {
        let discountAmount = 0;
        const { type, value, quantity } = discountItem;
        const discountType = type || null;
        const discountValue = parseFloat(value) || 0;
        const discountQuantity = quantity || 0;
        switch (discountType) {
          case 'percentage': {
            discountAmount = (parseFloat(
              totalAmount / 100,
            ) * discountValue).toFixed(2);
            totalAmount -= discountAmount * discountQuantity;
            break;
          }
          case 'amount': {
            discountAmount = (discountValue).toFixed(2);
            totalAmount -= discountAmount * quantity;
            break;
          }
          default:
            totalAmount -= 0;
        }
        return {
          ...discountItem,
          price: parseFloat(discountAmount),
          value: parseFloat(value),
        };
      });
    }
    const cartTotal = totalAmount > 0 ? totalAmount.toFixed(2) : 0;
    yield put(setCartDiscount(discount));
    yield put(setCartTotal(parseFloat(cartTotal)));
  });
}
/*
* Remove discount
*/
function* onRemoveCartDiscountFlow() {
  yield takeLatest(removeCartDiscount,
    function* onRemoveCartDiscount({ payload }) {
      const { id } = payload;
      const cartItems = yield select(getCartItems);
      const discounts = yield select(getCartCoupons);
      const cart = [...cartItems];
      let discount = [...discounts];
      const discountItemIndex = discount.findIndex((el) => el.id === id);
      if (discountItemIndex > -1) {
        const { quantity } = discount[discountItemIndex];
        if (quantity > 1) {
          discount = discount.map((el) => {
            if (el.id === id) {
              return { ...el, quantity: el.quantity - 1 };
            }
            return el;
          });
        } else {
          discount.splice(discountItemIndex, 1);
        }
      }
      // eslint-disable-next-line max-len
      const total = cart.length > 0 ? [...cart]
        .map((el) => parseFloat(el.quantity) * parseFloat(el.price))
        .reduce((pre, curr) => parseFloat(pre) + parseFloat(curr))
        : 0;
      // eslint-disable-next-line no-unused-vars
      let totalAmount = total;
      if (discount && discount.length > 0) {
        discount = discount.map((discountItem) => {
          let discountAmount = 0;
          const { type, value, quantity } = discountItem;
          const discountType = type || null;
          const discountValue = value || 0;
          const discountQuantity = quantity || 0;
          switch (discountType) {
            case 'percentage': {
              discountAmount = (parseFloat(
                totalAmount / 100,
              ) * discountValue).toFixed(2);
              totalAmount -= discountAmount * discountQuantity;
              break;
            }
            case 'amount': {
              discountAmount = (discountValue).toFixed(2);
              totalAmount -= discountAmount * discountQuantity;
              break;
            }
            default:
              totalAmount -= 0;
          }
          return {
            ...discountItem,
            price: parseFloat(discountAmount),
            value: parseFloat(value),
          };
        });
      }
      yield put(setCartDiscount(discount));
      yield put(setCartTotal(parseFloat(totalAmount.toFixed(2))));
    });
}

function* onSaveCartItemsFlow() {
  yield takeLatest(callSaveCart, function* onSaveCartItems({
    payload: { data, callback },
  }) {
    try {
      yield put(setLoading(true));
      if (data.length <= 0) {
        yield put(
          notifyError({
            message: 'INVOICES.OVERVIEW.CART.EMPTY',
          }),
        );
        return;
      }
      const auth = yield select(getAuth);
      if (
        !auth ||
        !auth.uid ||
        !auth.stsTokenManager ||
        !auth.stsTokenManager.accessToken
      ) {
        throw new Error('Cannot get Authentication');
      }

      const { items, total, currency, discount } = data;
      const descriptionItems = items.map(item => {
        const { quantity, name } = item;
        return `${quantity} x ${name}`;
      });

      const description = descriptionItems.join();

      const cartInfo = items.map(item => {
        const { quantity, name, price, id } = item;
        return { quantity, name, price, saleItemsId: id };
      });

      const discountInfo = discount.map(item => {
        const { currency, name, price, quantity, type, value } = item;
        return { currency, name, price, quantity, type, value };
      });
      const payload = {
        cartInfo,
        discountInfo,
        price: total,
        currency,
        description,
      };

      const response = yield call(apiUtil.invoice.quickInvoice, payload);
      if (response.success) {
        yield put(
          notifySuccess({
            message: 'INVOICE.OVERVIEW.CART.CHARGE_CREATED',
          }),
        );
      } else {
        yield put(
          notifyError({
            message: response.message,
          }),
        );
      }
      callback(response);
    } catch (error) {
      yield put(
        notifyError({
          message: 'INVOICE.OVERVIEW.CART.CHARGE_ERROR',
        }),
      );
    } finally {
      yield put(setLoading(false));
    }
  });
}

export default function* saga() {
  yield all([
    onAddCartItemFlow(),
    onRemoveCartItemFlow(),
    onAddDiscountCouponFlow(),
    onRemoveCartDiscountFlow(),
    onSaveCartItemsFlow(),
  ]);
}
