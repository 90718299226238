/* eslint-disable max-len */
import { fork } from 'redux-saga/effects';

import auth from './auth.saga';
import user from './user.saga';
import bitcoin from './bitcoin.saga';
import payments from './payments.saga';
import notification from './notification.saga';
import cart from './cart.saga';
import balances from './balances.saga';
import donation from '../../containers/Donation/donation.saga';
import invoices from '../../containers/Dashboard/Invoices/invoices.saga';
import withdrawals from '../../containers/Dashboard/Withdrawals/withdrawals.saga';
import checkout from '../../containers/Checkout/checkout.saga';
import payouts from '../../containers/Dashboard/Payouts/payouts.saga';

export default function* rootSagas() {
  yield fork(auth);
  yield fork(user);
  yield fork(bitcoin);
  yield fork(payments);
  yield fork(notification);
  yield fork(donation);
  yield fork(invoices);
  yield fork(withdrawals);
  yield fork(cart);
  yield fork(checkout);
  yield fork(balances);
  yield fork(payouts);
}
