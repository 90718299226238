import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect } from 'react-router';

import Loading from '../../components/Loading';
import { getCurrentUserProfile,
  getSurveyComplete } from '../../redux/ducks/auth.duck';
import { CASH_KYC_STATUS } from '../../constants/USER';

/**
 * @function withoutKYCEnabled
 * @param {*} Component
 * @description Allow user who already loggedin and verified email access component
 */
const withoutKYCEnabled = (Component) => (props) => {
  const user = useSelector(getCurrentUserProfile, shallowEqual);
  const surveyComplete = useSelector(getSurveyComplete, shallowEqual);

  if (isLoaded(user)) {
    if (!isEmpty(user)) {
      if (surveyComplete || !user.kycStatus || (
        user.kycStatus !== CASH_KYC_STATUS.APPROVED
        &&
        user.kycStatus !== CASH_KYC_STATUS.PENDING)) {
        return <Component {...props} />;
      }
      return <Redirect to="/dashboard" />;
    }
    return <Redirect to="/sign-in" />;
  }
  return <Loading open />;
};

export default withoutKYCEnabled;
